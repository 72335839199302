 <!-- Public Access Page : Guardian ID  -->

<template>
    <div class="main-container">

       <!-- Wallet connectd -->
        <div v-if="isConnect" class="wallet-connection"  >
            <div class="token-wrap">
                <div class="cfx-balance">{{accountCFXBalance}} CFX</div>
                <div class="neko-balance">{{accountNekoBalance}} NEKO</div>
                <div class="meow-balance">{{accountNFTBalance}}</div>
                <div  style="float:left;font-size:0.6em; color:#666;"> Conflux Network : {{accAddressStorten}} </div>
            </div>
        </div>


        <!-- Wallet not connected -->
        <div v-if="!isConnect" class="wallet-connection"  >
            <div class="token-wrap">
                <div class="cfx-balance"> Conflux networks</div>
                <el-button 
                    style="font-size:1.2em; margin-top:-5px; background-color:#ffe000; color:#ff3600; border:1px solid #ff3600;" 
                    @click="connectButton()"> Connect wallet
                </el-button>
            </div>
        </div>



    <div class="nft_info">
        <!-- Multi tab -->
        <el-card style="background:none;">
            <el-tabs type="border-card"  style="width: 500px;  margin-bottom:400px; background:black; border:none;">
                <!-- NFT Image -->
                <el-tab-pane label="NFT"  class="nft-image" >
                    <el-image :src="NFT.image">
                        <div slot="placeholder" class="image-slot">
                            loading<span class="dot">...</span>
                        </div>
                        <div slot="error" class="image-slot" @click="imageLoadError()">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                </el-tab-pane>


                <!-- Maneki Power -->
                <el-tab-pane label="Maneki power">
                    <el-table style="width:440px;" :data="NFT.attributes" stripe>
                        <el-table-column prop="trait_type" label="MANEKI POWER" width="180"> </el-table-column>
                        <el-table-column prop="value" label="value #" > </el-table-column>      
                    </el-table>
                </el-tab-pane>

                <!-- Chart -->
                <el-tab-pane label="Five Energies"  class="el-card-chart" >
                    <radar-chart :chart-data="chartData" :options="options" style=" width:400px; height:400px;background:white; padding-left:30px; padding-right:15px;"></radar-chart>
                </el-tab-pane>

                <!-- Certificate -->
                <el-tab-pane label="Certificate">
                        <el-table  :data="certificate" stripe style="width:440px;" >
                            <el-table-column prop="trait_type" label="Birth Certification" width="170"> </el-table-column>
                            <el-table-column prop="value" > </el-table-column>      
                        </el-table>
                        <div style="background-color:white; width:440px;">
                        <el-link :href=NFT.txURL target="_blank" class="linkVerify" type="primary" style="background-color:white; text-align:center; margin:0 auto; ">Verify Certificate</el-link>
                        </div>
                    <div style="clear:both;"></div>
                </el-tab-pane>
            </el-tabs>
        </el-card>


        <!-- Auction details -->
        <el-card  class="auction_detail" style="width: 500px; height:400px; background-color:#ffe000; color:#262626; margin-bottom:0;border-radius: 10px 10px  0 0;">    
    
            <div style="font-size:1.6em; padding:5px 10px;padding-top:0;">
                For Sale
                <div >{{NFT.name}} , 
                    <span>MEOW 猫 <span style="font-weight:bold">#{{NFT.id}}</span></span> 
                </div>
            </div>


            <div class="wrap-auction" style="color:black; padding:8px; background-color:white; border-radius:10px; border:1px solid #262626;">

                <div class="chart-frame" >
                    <div class="chart-left">
                        <div class="chart-start-price">{{parseInt(NFT.startingPrice/(10**18))}}</div>
                        <div class="chart-end-price">{{ parseInt(NFT.endingPrice/(10**18))}}</div>
                    </div>
                    <div class="chart-right">
                        <div class="chart-current-price" 
                        :style="{ 
                            'margin-top' : (lineChart.y_axis-5) + 'px', 
                            'margin-left' : (lineChart.x_axis + 20 ) + 'px', 
                            'background-image' : 'url(\'../../src/assets/images/coin-' + NFT.paymentTokenSymbol + '.png\')'  
                            }">
                                    {{NFT.paymentTokenSymbol}} {{ parseInt(NFT.currentPrice/(10**18))}} 
                        </div>
                        <div class="chart-line" :style="{ 'width': lineChart.width + 'px','height': lineChart.height + 'px' }" >
                            <div class="chart-dot" :style="{ 'margin-top' : lineChart.y_axis + 'px', 'margin-left' : lineChart.x_axis + 'px' }">
                            </div>
                        </div>
                    </div>

                    <div style="clear:both;"></div>

                    <div class="chart-bottom">
                        <div style="padding-bottom:10px; float:right;"> {{countdown}} </div>
                        <div style="font-size:0.8em; padding-bottom:10px; float:left;"> Maneki Auction </div>
                    </div>
                </div>
            </div>      
        </el-card>

        <!-- Buy Tab -->
        <el-card  class="auction_detail" style="width: 500px; height:140px; background-color:#ff4800; color:#262626; margin-top:0; border-radius: 0 0 10px 10px; ">  


            <div class="price-tab" style=" height:70px; ">  
                <!-- Action Button -->
                <el-button @click="withdraw()" style="float:right;width:150px; font-size:1.2em; background-color:#ffe000; color:#ff3600; border:1px solid #ff3600;">Withdraw</el-button>

                <!-- Price tag -->
                <div style="width:260px; border-radius:5px; float:left; background:white;padding:5px 15px;;"> Price <br>
                    <span style="font-size:1.5em;">{{ parseInt(NFT.currentPrice/(10**18))}} {{NFT.paymentTokenSymbol}} </span>
                </div>
            
            </div>
            
            
            <div style="clear:both;"></div>
            <div style="border:1px solid #ffb185; height:30px; padding:3px; border-radius:3px;">
                <!-- Auction link -->
                <div style="float:left; color:white; padding:5px; 15px; background:rgba(255, 255, 255, 0.2); "> https://auction.maneki.market/?MID={{NFT.id}} ... </div>


                <!-- Share Button -->
                <el-tooltip class="item" effect="dark" content="Copy and share this auction" placement="bottom-end">
                <el-button style="float:right; padding:3px 5px; font-size:1.2em; background-color:#ff4800; color:#ffb185; border:none;"
                    class="copyBtn"
                    data-test="test..."
                    :data-clipboard-text="diyContent"
                    icon="el-icon-document"> Copy
                </el-button>
                </el-tooltip>
            </div>
        </el-card>

       <!-- last price and sell Button -->
        <el-card  style="width: 500px; height:120px; background:#FFF6AD; text-align:center;">
            <div style="padding-bottom:10px; height:80px;float:left; width:35%;"> Last price<br>
                <span style="font-size:1.8em;"> {{ NFT.lastPrice }}</span> 
                <br>
                CFX
            </div>
            <div style="padding-bottom:10px; height:80px;float:left; width:30%; border-left:1px solid black;"> Blessing<br>
                <span style="font-size:1.8em;">{{2 - NFT.refCount}}</span>
                <br>
                left
            </div>
            <div style="padding-bottom:10px; height:80px; float:left; width:30%; border-left:1px solid black;"> Gene pool<br>
                <span v-if="NFT.gamma > 0" style="font-size:1.8em;">{{NFT.gamma}}</span>
                <span v-else style="font-size:1.8em;">-</span>
                <br>
                Meow#
            </div>

        </el-card>

        <el-card  style="width: 500px; height:200px; background : #ffe600; ">
            <div v-if="NFT.description" >
                <div class="el-card-wish">
                    <div style="background:#00ba2c; height:80px; padding:10px; color:#3f3f3f;  border-radius: 10px 10px 0 0;">{{NFT.description}}</div>
                </div>
            </div>
            <div >
                <div class="el-card-neko"> 
                    <div style="background:#7ac943; height:40px; padding:10px; color:#3f3f3f; border-radius:0 0 10px 10px;">{{ NFT.piggyBank }} NEKO</div>
                </div>
            </div>
        </el-card>
        <el-card style="height:300px; background:none;">
        </el-card>


        <div class="bottom clearfix"></div>

        <!-- Bidding  -->
        <el-dialog :visible.sync="dialog.bidding">
            
            <div class="dialog-input-wrap">
                <div class="dialog-content">
                    <div style="color:white; font-size:1.8em; padding-top:120px; width:350px; margin:0 auto; "> 
                    
                    <span style="color:yellow; font-size:0.7em;">入札を取り下げる ...</span><br>
                    Withdraw Bidding ... 
                    </div>
                </div>
            </div>


        </el-dialog>


        <!-- Bid success  -->
        <el-dialog :visible.sync="dialog.success">
            
            <div class="dialog-input-wrap">
                <div class="dialog-content" style=" padding-bottom:0px;">
                    <div style="color:white; font-size:1.8em; padding-top:100px; width:350px; margin:0 auto; "> 
                    
                    <span style="color:yellow; font-size:0.7em;">おめでとう!</span><br>
                    Withdraw successful, <br> MEOW 猫# {{NFT.id}} 
                    </div>
                </div>
                
                <div style="width:350px; margin:0 auto; padding-bottom:30px;"> Blockchain Tx : <br>
                    <el-link :href=NFT.TxLogURL target="_blank" class="linkVerify" type="primary">{{ NFT.TxLog }}</el-link>
                </div>
                
                <div slot="footer" class="dialog-footer">
                <router-link :to="'/marketplace/'"> 
                    <el-button style="margin:0 5px; background:none; padding-left:50px; padding-right:50px; color:white;" > Buy more</el-button>
                </router-link>
                <router-link :to="'/token/' + NFT.id+ '/0x0'"> 
                    <el-button type="gashapon"   style="margin:0 5px; padding-left:50px; padding-right:50px;" > Ok</el-button>
                </router-link>
                </div>

            </div>


        </el-dialog>

    </div>


 




    </div>
</template>

<script>

import abi_collectible from '../web3/abi_collectible';
import abi_exchange from '../web3/abi_exchange';

// Contract : ERC20 - $NEKO
import abi_neko from '../web3/abi_neko';

import RadarChart from '../charts/RadarChart';


import Clipboard from 'clipboard'


//Conflux SDK
const { Conflux } = require('js-conflux-sdk');
const conflux = new Conflux({
  url: 'https://main.confluxrpc.com',
  networkId: 1029,
});

console.log(conflux.version);
const provider = window.conflux



const path_TxLogURL = 'https://confluxscan.io/transaction/';
const path_Metadata = 'https://md.maneki.market/token/';
const path_Image = 'https://m.maneki.market/';



//connect Collectibles Contract 

const contract_collectibles =  conflux.Contract({abi:abi_collectible, address:'cfx:acedfgnc6tp0g8t2yy35gmr4pw4cjk7sgus4fmktnn'});
const contract_neko = conflux.Contract({abi:abi_neko, address:'cfx:acejgycpayveh1heb94c1yrdrd1y5t9mhpbmatdsx3'});
const contract_marketplace = conflux.Contract({abi:abi_exchange, address:'cfx:acdtn35x2bhabgknjambku8ztr9sp2gmh2ppr9u5d1'});

//connect Neko Contract
const contract_usdt = conflux.Contract({abi:abi_neko, address:'cfxtest:acepe88unk7fvs18436178up33hb4zkuf62a9dk1gv'});

    



    //const forwarderOrigin = 'http://localhost:8080'
    //const onboarding = new ConfluxPortalOnboarding({ forwarderOrigin })
    //onboarding.startOnboarding()


export default {
    components: {
      RadarChart
    },
    data(){
        return{
            isConnect: false,
            accountCFXBalance : 0,
            accountNekoBalance : 0,
            accountNFTBalance : 0,

            lineChart : {
                width  : 0,
                height : 0,
                x_axis : 0,
                y_axis : 197,
            },
            contractInstance:null,
            account:null,
            contract :{
                collectibles:null,
                payment:null,
            },
            display :{
                isConnect : false,
            },
            newNFT : {
                id      : '',
                DNA     : '',
                gammaID : '',
                power   : '',
                refCount: '',
                wish    : ''
            },
            NFT: {
                id : this.$route.params.id,
                transactionHash : null,
                txURL     : null,
                valuation : null,
                scarcity  : null,
                name:null,
                description:null,
                external_url:null,
                image: path_Image + this.$route.params.id + '.gif',
                wish:null,
                piggyBank : null,
                attributes: [{
                    trait_type:null,
                    value:null,
                    display_type:null
                }],
                auctionId     : null,
                paymentTokenAddress   : null,
                paymentTokenSymbol    : null,
                tokenID       : null,
                startingPrice : 0,
                endingPrice   : 0,
                duration      : 0,
                startAt       : 0,
                endAt         : 0,
                currentPrice  : 0,
                timeLeft      : 0,
                TxLog : null,
                TxLogURL : null
                
            },
            energies : {
                wealth      : 0,
                opportunity : 0,
                relationship: 0,
                wisdom      : 0,
                health      : 0
            },
            // Radarchart Setting and data
            chartData : null,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scale: {
                    ticks: {
                    min: 0,
                    max: 99999,
                    stepSize: 30000
                    }
                }
            },
            valuation : [],
            certificate : [],
            dialog :{
                approveToExchange : false,
                offerForm : false,
                wantToSell : false,
                approveTokenSpend : false,
                success :false,
                bidding : false
            },
            giftForm : {
                tokenID: '',
                receiverAddr:''
            },
            sellApproveForm : {
                tokenID: '',
            },
            sellForm : {
                sellerAddr :'',
                tokenID: '',
                paymentToken : '',
                startPrice : '',
                endPrice : '',
                duration :'',
            },
            formLabelWidth : '120px',
            countdown :0,
            localTime: '',
            runTimer : null,
            clipboard: null,
            diyContent: 'https://auction.maneki.market/'
         
        }
    },
    mounted() {

        this.initWallet();
 
        provider.on('accountsChanged', accounts => {
            this.accAddressStorten= accounts[0]
            this.initWallet()
            this.isConnect = true
            this.dialogActivate = false
            console.log('accountsChanged = ', accounts)
        })
 
        provider.on('chainChanged', chainId => {
            console.log('chainChanged,  = ', chainId)
            provider.request({method: 'cfx_netVersion'}).then(networkId => {
            console.log('networkId,  = ', networkId)
            })
        })


        this.getMetadata(this.$route.params.id);



        this.getMetadata(this.$route.params.id);
        this.getauctionDetails(this.$route.params.id); 
        this.runTimer = setInterval(this.showTime, 1000);

        // add clipboard copy

        this.clipboard = new Clipboard(".copyBtn");
        this.clipboard.on("success", this.successFunc)
        this.clipboard.on("error", this.errorFunc)


    },
    methods :{   
        

        initWallet(){
            
            // Fluent Extention Installed?
            if (window?.conflux?.isFluent) {
                // Installed
                this.walletInitialized()
            } else {
                //Fluent Not install, Request to make installation
                console.log('Fluent is not connected, please try refresh this page or restart your browser.')
                // Appear dialog , LINK - request install Fluent 
                //this.dialogInstall = true
            }
        },
        connectButton(){
            
            // Fluent Extention Installed?
            if (window?.conflux?.isFluent) {
                // Installed, Request authorized with wallet
                //this.walletInitialized()
                this.dialogActivate = true

            } else {
                //Fluent Not install, Request to make installation
                console.log('Fluent is not connected, please try refresh this page or restart your browser.')
                // Appear dialog , LINK - request install Fluent 
                this.dialogInstall = true
            }
        },
        async authorizedWallet(){
            console.log('Requesting account')
            provider
            .request({
            method: 'cfx_requestAccounts',
            })
            .then(this.walletInitialized())
            .catch(error => console.error('error', error.message || error))
        },
        async walletInitialized() {
            console.log('Fluent Wallet Connected')

            provider.request({method: 'wallet_getFluentMetadata'}).then(({version}) => {
                console.log('Fluent version :',version);
            })

            const [chainId, networkId, alreadyAuthedAddresses] = await Promise.all([
            provider.request({method: 'cfx_chainId'}),
            provider.request({method: 'cfx_netVersion'}),
            provider.request({method: 'cfx_accounts'}),
            ])

            console.log('initialized')
            console.log('chainId :',  chainId)
            console.log('networkId: ', networkId)          
            
            if (!alreadyAuthedAddresses.length) {
                // Appear Dialog - Coneect to a wallet FLUENT
                console.log ('no address',alreadyAuthedAddresses )

            } else {
                console.log ('Wallet Address :',alreadyAuthedAddresses[0])
                this.accAddress = alreadyAuthedAddresses[0]
                this.accAddressStorten  = alreadyAuthedAddresses[0]
                this.isConnect = true
                this.fetchData()
            }            


        },



        fetchData(){
                this.getNekoTokenBalance();
                this.getCFXBalance();
                this.getNFTBalance();
                this.getNFTDetails(this.$route.params.id);
        },

        async getNekoTokenBalance (){      
            await contract_neko.balanceOf(this.accAddress).then((res) => {
                console.log('Balance $NEKO:', (res.toString()/1e18).toFixed(2));
                this.accountNekoBalance = (res.toString()/1e18).toFixed(2);
            }).catch((err) => {
                console.log(err, 'err');
            });
        },

        async getCFXBalance(){
                await conflux.getBalance(this.accAddress).then((res) => {
                console.log('$CFX :', (res.toString()/1e18).toFixed(2));
                this.accountCFXBalance = (res.toString()/1e18).toFixed(2);
            }).catch((err) => {
                console.log(err, 'err!!');
            });
        },

        async getNFTBalance (){
            const balance = await contract_collectibles.balanceOf(this.accAddress);
            
            this.accountNFTBalance = balance[0];
            console.log('Total Guardian Owned:',balance); 
        },
        async getNFTDetails(_id){

            await contract_collectibles.Nekos(_id).call().then((res) => {

                this.NFT.id           = _id;
                this.NFT.power        = res[0]; 
                this.NFT.DNA          = res[1];
                this.NFT.refCount     = parseInt(res[2]);
                this.NFT.gamma        = parseInt(res[3]);
                this.NFT.piggyBank    = parseInt(res[4].toString()/10**18).toLocaleString() ;
                this.NFT.lastPrice    = parseInt(res[5].toString()/10**18).toLocaleString() ;


                console.log('Blessing used: ', parseInt(res[2]));
                
            })    

        },

        fillData (a,b,c,d,e) {

            console.log('pre-filling :');

            this.chartData = {
                labels: [ "Wealth",	"Opportunity","Relationship", "Wisdom",	"Health"],
                datasets: [
                {
                    label: 'Maneki Power',
                    borderWidth: 0.5,
                    fill: true,
                    backgroundColor: 'rgba(255, 99, 132,0.2)',
                    borderColor: 'rgb(255, 99, 132,0.2)',
                    pointBackgroundColor: 'rgb(255, 99, 132)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(255, 99, 132)',
                    data: [a, b, c, d, e]
                },
                ]
            }
            console.log('filling oppotunity:',b);
        },
        getCertificate (){

            this.certificate.push ({
                trait_type: 'Token ID',
                value: this.$route.params.id,
            });

            this.certificate.push ({
                trait_type: 'Name',
                value: this.NFT.name,
            });

            this.certificate.push ({
                trait_type: 'Guardian',
                value: this.NFT.guardian,
            });
            this.certificate.push ({
                trait_type: 'Gamma',
                value: this.NFT.gamma,
            });
            this.certificate.push ({
                trait_type: 'DNA',
                value: this.NFT.art_dna,
            });
            this.certificate.push ({
                trait_type: 'Blockchain Certificate',
                value: this.NFT.transactionHash,
            });


        },
        getValuation (pow){
            //valuation return the decimal within 0 - 1
            //scarcity 0 - 100%
            var Power = pow/2;
            var Mean  = 250000;
            var Range = 250000;
            var BasePrice = 10.00;

            if (Power>250000){
                // power above 250k
                this.NFT.valuation = (((Power-Mean)/100000)**5)/97.65625;
            } else {
                // power below 250k
                this.NFT.valuation = (((Range-Power)/100000)**5)/97.65625;
            }
            
            // x10
            if (this.NFT.valuation * 100 < 18.8 ){
                this.NFT.scarcity = 18.8 + this.NFT.valuation * 10;
            } else {
                this.NFT.scarcity = this.NFT.valuation * 100;
            }
            this.NFT.valuation = parseFloat(BasePrice + this.NFT.valuation * 500).toFixed(4);

            // store data to valuation

            this.valuation.push ({
                trait_type: 'Valuation',
                value: this.NFT.valuation,
            })

            this.valuation.push ({
                trait_type: 'Luckiness',
                value: this.NFT.scarcity,
            })

            this.valuation.push ({
                trait_type: 'Limited',
                value: 'Yes',
            })

            this.valuation.push ({
                trait_type: 'Transcacted',
                value: 5,
            })

            this.valuation.push ({
                trait_type: 'Coins invited',
                value: 123132132,
            })

            this.valuation.push ({
                trait_type: 'Gamma',
                value: this.NFT.gamma,
            })

            this.valuation.push ({
                trait_type: 'Guardian',
                value: this.NFT.guardian,
            })

            console.log ('valuation :', this.NFT.valuation);
            console.log ('Power :', pow);


        },
        async getMetadata(id){
            const axios = require('axios');
            await axios.get( path_Metadata +id)
            .then((res) => {
                console.log ('metadata :',res.data);
                //import data form metadata
                this.NFT.id = id;
                this.NFT.transactionHash = res.data.transactionHash;
                this.NFT.txURL  = path_TxLogURL + res.data.transactionHash;
                this.NFT.name = res.data.name;
                this.NFT.id = res.data.id;
                this.NFT.guardian = res.data.guardian;
                this.NFT.gamma = res.data.gamma;
                this.NFT.description = res.data.description;
                this.NFT.external_url = res.data.external_url;
                this.NFT.art_dna = res.data.art_dna;
                this.NFT.image = res.data.image;
                this.NFT.attributes = res.data.attributes;

                //fill in data for charting
                this.fillData(
                    parseInt(res.data.attributes[5].value.toString().replace(',', '')),
                    parseInt(res.data.attributes[6].value.toString().replace(',', '')),
                    parseInt(res.data.attributes[7].value.toString().replace(',', '')),
                    parseInt(res.data.attributes[8].value.toString().replace(',', '')),
                    parseInt(res.data.attributes[9].value.toString().replace(',', ''))
                    );

                //make valuation
                this.getValuation(res.data.attributes[3].value.toString().replace(',', ''));

                //pop out wish message
                //this.popWish(this.NFT.name +' bring this wish come true!', this.NFT.description);

                //birth certification 
                this.getCertificate();

                console.log('Guardian Metadata', this.NFT);

            }).catch((err) => {
                console.log(err, 'err');
            });    
        },
        imageLoadError () {
            console.log('Image failed to load');
        },

        openWantToSellDialog (_tokenID) {
            console.log('open want to sell dialog')
            this.dialog.wantToSell = true;
            this.sellForm.tokenID = _tokenID;
        },
        async OpenTokenApproveDialog(){

            await window.conflux.request({method: "cfx_requestAccounts"}).then((currentWalletAddr) => {

                console.log('token approval ', currentWalletAddr)
                console.log('token selected ', this.NFT.paymentTokenSymbol, this.NFT.currentPrice)

                if(this.NFT.paymentTokenSymbol=='NEKO'){
                        contract_neko.approve(contract_marketplace.address, this.NFT.currentPrice).sendTransaction({
                            from: currentWalletAddr,
                            gas: '0x249F0', //150,000
                            storageLimit: '0x1F4', //500
                            gasPrice: '0x1388', //5000
                        }).confirmed().then((receipt) => {
                            console.log ('approve use NEKO :', receipt)
                            
                            this.dialog.approveTokenSpend = true;

                        })

                } else if (this.NFT.paymentTokenSymbol=='USDT'){
                        contract_usdt.approve(contract_marketplace.address, this.NFT.currentPrice).sendTransaction({
                            from: currentWalletAddr,
                            gas: '0x249F0', //150,000
                            storageLimit: '0x1F4', //500
                            gasPrice: '0x1388', // 5000
                        }).confirmed().then((receipt) => {
                            console.log ('approve use USDT :', receipt)
                            this.dialog.approveTokenSpend = true;
                            

                        })
                }
            })
            
        },
        async payWithToken(){  
            
            await window.conflux.request({method: "cfx_requestAccounts"}).then((currentWalletAddr) => {
                //pay with ERC20 Token
                console.log ('pay for TokenID :', this.NFT.tokenID) 
                console.log ('pay by Token :', this.NFT.paymentTokenAddress) 
                console.log ('current price :', this.NFT.currentPrice) 
                
                contract_marketplace.bidByERC20(this.NFT.tokenID, this.NFT.paymentTokenAddress, this.NFT.currentPrice).sendTransaction({
                    from: currentWalletAddr,
                    gas: '0x493E0', //300,000
                    storageLimit: '0x1F4', // 500
                    gasPrice: '0xBB8', //3000
                }).confirmed().then((receipt) => {
                    console.log ('Succesfully bought!', receipt )

                })
            })

        },
        async withdraw(){
            console.log('withdraw this NFT : ',this.NFT.tokenID )

            this.dialog.bidding = true;

// ---
            const [connectedAddress] = await provider.request({method: 'cfx_accounts'});

            const tx = {
            from: connectedAddress,
            to: contract_marketplace.address,
            gas: '0x2bf20', //180,000
            storageLimit: '0x0', //0
            gasPrice: '0x3b9aca00', //3000
            data: contract_marketplace.cancelAuctionByTokenId( this.NFT.tokenID
            ).data,
            }
         
            provider
            .request({method: 'cfx_sendTransaction', params: [tx]})
            .then(receipt => {
    
                         this.dialog.bidding = false;
                         
                        this.NFT.TxLog = receipt['transactionHash'].substring(0, 15) + '...' + receipt['transactionHash'].substring(60, 66)
                        this.NFT.TxLogURL = path_TxLogURL + receipt['transactionHash']

                        this.dialog.success = true;
                        console.log ('Succesfully bought!', receipt );

            })
            .catch(error => console.error('error', error.message || error))
    


//--- 

        },
        async getauctionDetails(_tokenId){

            // get premittive details 
            await contract_marketplace.getAuctionByTokenId(_tokenId).call().then((res) => {
                console.log('auction details :', res);

                // get current price 
                contract_marketplace.getCurrentPriceByTokenId(_tokenId).call().then((res2) => {
                    console.log('current price:', res2.toString());

                        this.NFT.auctionId              = res[0].toString()
                        this.NFT.sellerAddr             = res[1]
                        this.NFT.tokenID                = res[2].toString()
                        this.NFT.paymentTokenAddress    = res[3]
                        this.NFT.paymentTokenSymbol     = this.determinePaymentToken(res[3])
                        this.NFT.startingPrice          = res[4].toString()
                        this.NFT.endingPrice            = res[5].toString()
                        this.NFT.duration               = res[6].toString()
                        this.NFT.startAt                = res[7].toString()
                        this.NFT.endAt                  = parseInt(res[7]) + parseInt(res[6])
                        this.NFT.currentTime            = Math.floor(Date.now() / 1000);
                        this.NFT.timeLeft               = this.getTimeRemaining (this.NFT.endAt)
                        this.NFT.currentPrice           = res2.toString()

                        this.setChartData(
                            270,
                            200,
                            this.NFT.startingPrice, 
                            this.NFT.endingPrice, 
                            this.NFT.currentPrice, 
                            this.NFT.startAt, 
                            this.NFT.endAt, 
                            this.NFT.currentTime 
                        );


                        this.diyContent = 'Check out my latest #ManekiMeow #NFT with my wish for you!  https://auction.maneki.market/?MID='+ this.NFT.tokenID +'&TOKENSYMBOL='+ this.NFT.paymentTokenSymbol +'&PRICE=' + parseInt(this.NFT.currentPrice/(10**18))

                })
            })

        },
        getTimeRemaining(endtime){
            var total = 0;

            if((endtime*1000)>Date.parse(new Date())){
                total = (endtime*1000) - Date.parse(new Date());
                console.log('total :', total);
                
                const seconds = Math.floor( (total/1000) % 60 );
                const minutes = Math.floor( (total/1000/60) % 60 );
                const hours = Math.floor( (total/(1000*60*60)) % 24 );
                const days = Math.floor( total/(1000*60*60*24) );

                this.countdown ='';

                if (days!=0){
                    this.countdown = days + 'd ';
                } 

                if(hours!=0){
                    this.countdown = this.countdown.concat(hours + 'h ');
                }

                if(minutes!=0){
                    this.countdown = this.countdown.concat(minutes + 'm ');
                }

                if(seconds!=0){
                    this.countdown = this.countdown.concat(seconds + 's ');
                }
            } else {
                this.countdown =0;

                //clearInterval(this.runTimer);
                this.currentTime = this.endAt;
            }

            //this.countdown = days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's';

            //setInterval( this.getTimeRemaining(endtime-1000),1000);

            console.log('countdown : ', this.countdown);

            //return { total, days, hours, minutes, seconds };
        },
        getCurrentPrice(){
            let secondsPassed = (Date.parse(new Date())/1000) -  (this.NFT.startAt);

            console.log('secondsPassed : ', secondsPassed)
            console.log('duration : ', this.NFT.duration)

            if (secondsPassed >= this.NFT.duration) {
                this.NFT.currentPrice = this.NFT.endingPrice;
                this.NFT.currentTime  = this.NFT.endAt;
            } else {
                let totalPriceChange = this.NFT.startingPrice - this.NFT.endingPrice;
                let currentPriceChange = totalPriceChange * secondsPassed / this.NFT.duration;
                let currentPrice = this.NFT.startingPrice - currentPriceChange;
                this.NFT.currentPrice = currentPrice;

            console.log('currentPrice : ', currentPriceChange)
            }
        },
        showTime() {

            //let dateTime= new Date();
            //let time = dateTime.toLocaleTimeString();
            this.getTimeRemaining (this.NFT.endAt);
            this.getCurrentPrice();

            this.setChartData(
                270,
                200,
                this.NFT.startingPrice, 
                this.NFT.endingPrice, 
                this.NFT.currentPrice, 
                this.NFT.startAt, 
                this.NFT.endAt, 
                this.NFT.currentTime +=1 
            );

            //console.log('time :', time);
        },
        determinePaymentToken (_tokenAddress){
            console.log('pay token address',_tokenAddress);
            let lable = 0;
            if(_tokenAddress == "cfxtest:acepe88unk7fvs18436178up33hb4zkuf62a9dk1gv"){
                lable = 'USDT'
            } else if (_tokenAddress == "cfxtest:aca9u5eu3zvmjv6gdudvf21g9uj2cm60upya76k4e4"){
                lable = 'NEKO'
            } else {
                lable = 'CFX'
            }
            return lable
        },
        setChartData (_width, _height, _startingPrice, _endingPrice, _currentprice, _startAt, _endAt, _currentTime){


            _startingPrice = (_startingPrice / 10e17);
            _endingPrice   = (_endingPrice / 10e17);
            _currentprice  = (_currentprice / 10e17);

            let yAxis = parseInt ( (_startingPrice - _currentprice) / (_startingPrice - _endingPrice) * _height );
            let xAxis = 0;

            if(_currentTime < _endAt){
                xAxis = parseInt ( (_startAt - _currentTime) / (_startAt - _endAt) * _width );
            } else {
                xAxis = _width;
                yAxis = _height;
            }

            //let yAxis = 300;
            //let xAxis = 300;

            //var yAxis = (_startingPrice - _endingPrice) / _endingPrice * width;
            //var xAxis = (_endAt - _startAt) / _startAt * height;

            let bias = 4;

            this.lineChart.width = _width;
            this.lineChart.height = _height;
            this.lineChart.y_axis = yAxis - bias;
            this.lineChart.x_axis = xAxis - bias; 

            console.log('startingPrice :' + _startingPrice + ' endingPrice :' + _endingPrice + ' current price :' + _currentprice);


            console.log('_startAt :' + _startAt + ' _endAt :' + _endAt + ' _currentTime :' + _currentTime);

            console.log('yAxis :' + yAxis);
            console.log('xAxis :' + xAxis);

        },

        successFunc (e) {
        console.info("Action:", e.action);
        console.info("Text:", e.text);
        console.info("Trigger:", e.trigger);
        // 可以取到目标元素上的自定义属性（可以据此再做一些处理）
        e.trigger.dataset.test && console.log(e.trigger.dataset.test)
        // 清除选中状态
        e.clearSelection();

        this.$notify({
            title: 'Auction URL copied',
            message: 'you may paste and promote on your social media',
            type: 'success',
            showClose: false
        });
        },
        errorFunc (e) {
        console.error("Action:", e.action);
        console.error("Trigger:", e.trigger);

        this.$notify.error({
            title: 'Failed',
            message: 'Error, retry',
            showClose: false
        });
        },
        beforeDestroy() {
            this.clipboardclipboard.off("error");
            this.clipboardclipboard.off("success");
            this.clipboardclipboard.destroy();

        }
        
     
    }
}

</script>

<style scoped>
.main-container{
}


.nft_info {
    padding-top: 100px;
    width:1050px;
    margin: 0 auto;
    padding-bottom: 30px;
    z-index: 1;
}
.nft-image {
    padding:0px;
    margin:0px;
    width:440px;
    height: 440px;
}
.el-tabs--border-card{
    box-shadow: none!important; 
    padding: 0px !important;
}
.el-tabs--border-card{
    background-color: white;
    border:1px solid white;
}
.el-tabs {
    padding: 0 !important; 
}
.el-card {
    width:500px;
    float:left;
    margin-right: 20px;
    margin-bottom: 30px;
    background:rgba(0, 0, 0, .4); 
    border: none;
}
.el-card-chart {
    float:left;
    border: none;
}
.el-card-wish{
    background-image: url("../../src/assets/images/icon-wish.png");
    background-repeat: no-repeat;
    background-position: top left; 
    padding-left:60px;
    color:white; 
    font-size:1.2em;
    min-height: 100px;
}
.el-card-name {
    background-image: url("../../src/assets/images/icon-meow.png");
    background-repeat: no-repeat;
    background-position: top left; 
    padding-left:60px;
    color:white; 
    font-size:1em;
    min-height: 100px;
}
.el-card-neko {
    background-image: url("../../src/assets/images/icon-neko.png");
    background-repeat: no-repeat;
    background-position: top left; 
    padding-left:60px;
    color:white; 
    min-height: 100px;
    font-size: 2em;
    
}


.el-card-meta {
    background:rgba(0, 0, 0, .4); 
}
.el-table-meta {
    background-color: none;
}
.linkVerify {
    padding: 10px 0;
    margin:0 auto;
    text-align: right;
}

.dialog-input-wrap{
    width:500px; 
    height:420px;
    margin:0 auto; 
    padding-bottom:15px; 
    padding-top:20px; 
    padding-left:20px; 
    padding-right:20px; 
    border:1px solid #000;  
    background:rgba(0, 0, 99, 0.8);  
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
.dialog-content{
    height:250px;
}


.dialog-footer {
    text-align: center;
}
.auction_detail{
}
.auction_detail ul{
    list-style: none;
    color: #ccc;
}
.chart-frame{
    background: white;
    height: 200px;
    padding: 5px;
    padding-bottom :40px;
}
.chart-left {
    float: left;
    width: 60px;
    font-size:0.8em;
}
.chart-right {
    float: left;
}
.chart-bottom {
    margin:0 auto;
    text-align: right;
    padding-right:10px;
    padding-top:5px;
}
.chart-label{
    position: relative;
}
.chart-line {
    border: 1px solid white;
    border-left: 2px solid seagreen;
    border-bottom: 2px solid seagreen;
    background-image: url('../../src/assets/images/line_chart_bg-01.png');
    background-repeat: no-repeat;
    background-size: initial;
    
}
.chart-current-price {
    float: left;
    font-size:0.8em;
}
.chart-start-price {
    text-align: right;
    width:55px;
}
.chart-end-price {
    text-align: right;
    margin-top: 170px;
    width:55px;
}
.chart-dot {
    width:10px; height:10px; border-radius: 25px; background-color:green;
}
.wallet-connection{
    width: 460px;
    text-align: right;
    color: #c6c6c6;
    float:right;
    margin-right:30px;
}
.token-wrap{
    width: 460px;
    height: 30px;
    padding:20px;
    margin-top:-20px;
    background: #3f3f3f;
    border-radius: 0 0 10px 10px;
    background:rgba(0, 0, 0, .4); 
    border:solid 1px #0086ff;
}
.cfx-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-cfx.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:140px;
    float:left;
    color:white;
}
.neko-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-neko.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:140px;
    color:#fcee21;
    float:left;
}
.meow-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-meow.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:60px;
    color:#fcee21;
    float:left;
}
</style>
