<template>
    <div class="mint_container">
        <!--
        <div class="wallet-connection" style="text-align:right;" >
            <el-button @click="updateButton()" v-if="!display.isConnect"> connect wallet </el-button>
            <div style="color:white" v-if="display.isConnect"> Conflux Network : {{shortenAccount}} </div>
        </div>
        -->

        <div class="alert-msg" > 
            <br> Report bugs <a href="https://discord.com/invite/Ev8aX9HQWm" target="_blank">DISCORD </a> or <a href="https://t.me/ManekiMeow_MY" target="_blank"> Telegram </a>
        </div>

        
        <h1>Marketplace</h1>


        <div style="background:none; margin:50px auto;">


            <div  v-if="isLoadingListing" style="margin:0 auto; padding:30px; width:300px;">

            </div>

            <ul class="list" 
                v-loading="isLoadingListing"
                element-loading-text="Loading on Conflux Network..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)">

                <li class="item"  v-for="(item) in NFTs" :key="item.id" >
                    <div class="item-wrap">
                        <div class="el-card-name">
                            <div> 
                                <div v-if="item.name"> {{item.name}} </div>
                                <div v-if="!item.name">Maneki 🐱</div>
                                <span style="color:#0375fc; font-size:0.8em;">Guardian 招き猫 <span style="font-weight:bold">#{{item.id}}</span></span>
                            </div>
                        </div>
                        <router-link :to="'/auction/' + item.id +'/0x0/'">
                            <el-card :body-style="{ padding: '0', }">
                                <img :src="item.url" class="image">
                            </el-card>
                        </router-link>
                        <!--
                        <div v-if="item.description" >
                            <div class="el-card-wish">{{item.description}}</div>
                        </div>  
                        -->
                        <div class="wrap_price" style=" background:rgba(255, 255, 255, 0.8);  color:#ff4800; margin:0 auto width:300px ;height:40px;padding:20px; ">
                            <div style="float:left; width:130px;height:40px; "> 
                                <span style="font-size:1em;">Price </span><br>
                                <span style="font-size:1.5em; color:black;">{{item.paymentToken}} {{ parseInt(item.currentPrice/(10**18))}} </span>
                            </div>
                            <router-link :to="'/auction/' + item.id +'/0x0'"  style="float:right;">
                                <el-button >  Buy </el-button>
                            </router-link>
                        </div>
                        <!-- <div> Time left {{ item.timeLeft }} </div> -->

                        <!--
                        <ul>
                            <li>auctionId :     {{item.auctionId}}</li>
                            <li>currentPrice :  {{item.paymentToken}} {{ parseFloat(item.currentPrice/(10**18)).toFixed(4)}}</li>
                            <li>Start Price :   {{item.paymentToken}}  {{parseFloat(item.startingPrice/(10**18)).toFixed(4)}}</li>
                            <li>End Price :     {{item.paymentToken}}  {{ parseFloat(item.endingPrice/(10**18)).toFixed(4) }}</li>
                            <li>Start at :      {{item.startAt}}  </li>
                            <li>duration :      {{item.duration}}</li>
                            <li>  {{item.timeLeft}} left</li>
                            <li>  {{ item.timeLeft/3600 }} left</li>
                        </ul>
                        -->

                        <div class="bottom clearfix"></div>
                    </div>

                </li>

                <div style="clear:both;"></div>
            </ul>
        </div>


    



    </div>
</template>

<script>

import abi_collectible from '../web3/abi_collectible';
import abi_exchange from '../web3/abi_exchange';
import abi_neko from '../web3/abi_neko';


//Conflux SDK
const { Conflux } = require('js-conflux-sdk');
const conflux = new Conflux({
  url: 'https://main.confluxrpc.com',
  networkId: 1029,
});

console.log(conflux.version);
//const provider = window.conflux
    
//connect Collectibles Contract 
const contract_collectibles =  conflux.Contract({abi:abi_collectible, address:'cfx:acedfgnc6tp0g8t2yy35gmr4pw4cjk7sgus4fmktnn'});
const contract_neko = conflux.Contract({abi:abi_neko, address:'cfx:acejgycpayveh1heb94c1yrdrd1y5t9mhpbmatdsx3'});
const contract_marketplace = conflux.Contract({abi:abi_exchange, address:'cfx:acdtn35x2bhabgknjambku8ztr9sp2gmh2ppr9u5d1'});


  
//const path_TxLogURL = 'https://confluxscan.io/transaction/';
const path_Metadata = 'https://md.maneki.market/token/';
//const path_Image = 'https://m.maneki.market/';
 

export default {
    data(){
        return{
            activeIndex: '1',
            contractInstance:null,
            account:null,
            isCollapse:false,
            tokenSupply:0,
            name:null,
            loading : true,
            isLoadingListing : true,
            
            display :{
                isConnect : false,
            },
            // NFT 
            totalGuardian :0,
            NFT : {
                owned:0,
                supply:0,
                balance:0
            },
            NFTs :[],
            search: '',
            contract :{
                collectibles:null,
                exchange :null
            },
            dialog :{
                approveToExchange : false,
                offerForm : false,
                sendGift : false,
            },
            approvalForm: {
                tokenID: ''
            },
            offerForm: {
                tokenID: '',
                price: '',
                currency:'',
                buyer:''
            },
            giftForm : {
                tokenID: '',
                receiverAddr:''
            },
            formLabelWidth: '120px',
            coinBalance : [],
            coinPrice :'',
            web3:null,

        }
    },
    mounted() {
            
            console.log('collectibles : ', contract_collectibles);
            console.log('exchange : ', contract_marketplace);
            console.log('the neko : ', contract_neko);
            this.getAuctions();
 
    },
    methods :{

        async getAuctions() {
            contract_marketplace.getActiveAuctions().call().then((auctions) => {

                console.log('total auction : ',auctions.length);


           
                for( let i= auctions.length -1  ; i > 0 ; i--){
                    console.log (i);

                    let currentPrice = 0

                    contract_marketplace.getCurrentPriceByAuctionId(auctions[i]).call().then((price) => {
                        currentPrice = price.toString();
                        console.log('current price :', currentPrice)
               
                        // get current auction details
                        contract_marketplace.getAuctionByAuctionId(auctions[i]).call().then((res) => {

                            this.isLoadingListing = false;

                            console.log('Auction valid :', res);
                            //if(res[0]!='-32603'){
                                //0 auction.id,
                                //1 auction.seller,
                                //2 auction.tokenId,
                                //3 auction.paymentToken,
                                //4 auction.startingPrice,
                                //5 auction.endingPrice,
                                //6 auction.duration,
                                //7 auction.startedAt

                                var startUnixTime = new Date(res[7].toString()*1000);

                                let nowUnixTime = Math.floor(Date.now() / 1000)

                                let timeLeft = nowUnixTime - res[7].toString()

                                console.log('start datetime :', startUnixTime.toLocaleString());
                                console.log('startUnixTime :', res[7].toString());
                                console.log('nowUnixTime :', nowUnixTime);
                                console.log('timeLeft :', timeLeft);

                                
                                this.pumpNekos(
                                    res[0].toString(), // _auctionId
                                    res[1],            // _seller
                                    res[2].toString(), // tokenId
                                    this.determinePaymentToken(res[3]),    // _paymentToken
                                    res[4].toString(), // _startingPrice
                                    res[5].toString(), // _endingPrice
                                    res[6].toString(), // _duration
                                    startUnixTime, // _startAt
                                    currentPrice,      // _currentPrice
                                    timeLeft           // _timeLeft
                                    );
                            
                            


                        }).catch((err) => {
                            console.log(err, 'err');
                        });


                    })
                }
            })
        },


        pumpNekos(_auctionId, _seller,  _tokenId, _paymentToken, _startingPrice, _endingPrice, _duration, _startAt, _currentPrice, _timeLeft ){

                // retrive NFT Details
                const axios = require('axios');
                axios.get(path_Metadata+ _tokenId)
                .then((metadata) => {

                    this.NFTs.push({
                        id: _tokenId,
                        power: metadata.data.attributes[3].value,
                        DNA: metadata.data.art_dna,
                        url: metadata.data.image,

                        name : metadata.data.name,
                        description : metadata.data.description,
                        image : metadata.data.image,
                        gamma : metadata.data.gamma,

                        auctionId : _auctionId,
                        seller : _seller,
                        paymentToken : _paymentToken,
                        startingPrice : _startingPrice,
                        endingPrice : _endingPrice,
                        duration : _duration,
                        currentPrice : _currentPrice,
                        startAt : _startAt,
                        timeLeft : _timeLeft

                    });

                }).catch((err) => {
                    console.log(err, 'err');
                });
                console.log ("NFTs :",this.NFTs)


        },
        determinePaymentToken (_tokenAddress){
            let lable = 0;
            if(_tokenAddress == "cfxtest:acepe88unk7fvs18436178up33hb4zkuf62a9dk1gv"){
                lable = 'USDT'
            } else if (_tokenAddress == "cfxtest:aca9u5eu3zvmjv6gdudvf21g9uj2cm60upya76k4e4"){
                lable = 'NEKO'
            } else {
                lable = 'CFX'
            }
            return lable
        }

    }
}
</script>

<style scoped>
h1{
    width: 25%;
    font-size: 2em;
    color:rgba(255, 255, 0, 0.7);
    margin:0 auto;
    font-weight: 100;
    margin-bottom: 30px;
    text-align: center;
}
.Asset-Summery {
    float: left;
    min-width: 350px;
    background:rgba(128, 255, 209, .2); 
    margin:15px 20px;
    padding: 5px 10px;
}
.list {
    top:50px;
    width: 1100px;
    padding:0;
    margin:0 auto;
}
.item {
    border: 1px solid black;
    width: 250px;
    height : 400px;
    float:left;
    margin:15px 5px;
    padding: 5px 5px;
    padding-bottom: 30px;
    background:rgba(0, 0, 0, .6); 
    border-radius: 10px;
    list-style: none;
}
.el-card-wish{
    background-image: url("../../src/assets/images/icon-wish.png");
    background-repeat: no-repeat;
    background-position: top left; 
    padding-top: 5px;
    margin-top:5px;
    padding-left:60px;
    color:white; 
    font-size:1.2em;
    min-height: 100px;
}
.el-card-name{
    background-image: url("../../src/assets/images/icon-meow.png");
    background-repeat: no-repeat;
    background-position: top left; 
    padding-left:60px;
    color:white; 
    font-size:1.2em;
    min-height: 60px;
}


.alert-msg {
    color: yellow; 
    padding:20px; 
    width:40%; 
    margin:0 auto; 
    border:0px solid red; 
    float:right; 
    background:rgba(0, 0, 0, .4);
}
.alert-msg a:link{
    color : white;
    text-decoration:none;
    font-weight: 600;
}

.alert-msg a:visited {
    color : white;
    text-decoration:none;
    font-weight: 600;
}
.alert-msg a:hover {
    color: hotpink;
}
</style>