<template>
    <el-container>
            <el-header height="85px">

                <img class="maneki-logo" src="../../src/assets/images/logo-maneki_logo.png" v-on:click="goHome()"/>

                <el-menu  class="el-main-menu" mode="horizontal" router style="float:right; padding-top:20px; text-align:center; text-color:#fff;  active-text-color:#ffd04b">

                    <!-- <el-menu-item class="nav-makeWish" index="/featured">Special</el-menu-item> 
                    <el-menu-item class="nav-makeWish" index="/metapass/guardian">元證 Metapass</el-menu-item>   -->  
                    <el-menu-item class="nav-wishes" index="/wishingWall">Wishes</el-menu-item>
                    <el-menu-item class="nav-makeWish" index="/wish/9987">願意 Mint a wish</el-menu-item>   
                    <el-menu-item class="nav-marketplace" index="/marketGroup/1/1000000/0">市場 Marketplace</el-menu-item>
                    <!-- <el-menu-item class="nav-wishes" index="/search/0">资产 Assets</el-menu-item> -->

                    <el-submenu index="/wallet">
                    <template slot="title">
                        <i class="iconfont icon-iconwallet"></i>
                        <span slot="title">NFT</span>
                    </template>
                        <el-menu-item-group>
                            <!--<el-menu-item index="/connect">Connect</el-menu-item>-->

                            <!--
                            <el-menu-item class="nav-wishes" index="/wishingWall">Wishes</el-menu-item>
                            <el-menu-item class="nav-wishes" index="/search/cfx:aakm8cnzwf31gj84t90v50f7c8fxp0rphp23en6m0n">资产 Assets</el-menu-item>
                            -->
                            <el-menu-item index="/wallet">My MMGC</el-menu-item>
                            <el-menu-item index="/party">My listing</el-menu-item>
                            <el-menu-item v-on:click="goLaunchpad()"> Launchpad</el-menu-item>
                            <el-menu-item v-on:click="goBuyYourCFX()"> Get your CFX</el-menu-item>
                            <el-menu-item  v-if="isAuthorized" index="/marketGroup/600000/650000">Power 600,000</el-menu-item>
                            <el-menu-item  v-if="isAuthorized" index="/wishRoot/1">Root Mint</el-menu-item>
                            <el-menu-item  v-if="isAuthorized" index="/wishLoop/1">Loop Mint</el-menu-item>
                            <el-menu-item  v-if="isAuthorized" index="/wishBatch/1">Batch Mint</el-menu-item>
                            <el-menu-item  v-if="isAuthorized" index="/wishPaint/1">Repaint Meow</el-menu-item>
                            <el-menu-item  v-if="isAuthorized" index="/wishIpfs/1">Store IPFS</el-menu-item>
                            <!-- <el-menu-item index="/earn">Earn</el-menu-item> -->
                            <!-- <el-menu-item index="/sentgift">Gift</el-menu-item> -->
                            <!-- <el-menu-item index="/clubhouse">Clubhouse</el-menu-item> -->
                            <!-- <el-menu-item index="/painter">Painter</el-menu-item> -->
                        </el-menu-item-group>
                    </el-submenu>
                </el-menu>
            </el-header>
            <el-container>
                <el-main>
                    <router-view/>
                </el-main>
            </el-container>
    </el-container>
</template>

<script>

//Conflux SDK
const { Conflux } = require('js-conflux-sdk');
const conflux = new Conflux({
  url: 'https://main.confluxrpc.com',
  networkId: 1029,
});

console.log(conflux.version);
const provider = window.conflux



export default {
    data(){
        return{
            isCollapse:true,
            hallo: 123,
            isAuthorized : false,
        }
    },
    mounted(){
        console.log('Hallo :', this.hallo);
  
        this.authorization();

    },

    methods : {
        async authorization (){
            console.log('authorization ');
            const [chainId, networkId, alreadyAuthedAddresses] = await Promise.all([
            provider.request({method: 'cfx_chainId'}),
            provider.request({method: 'cfx_netVersion'}),
            provider.request({method: 'cfx_accounts'}),
            ])

            console.log('initialized')
            console.log('chainId :',  chainId)
            console.log('networkId: ', networkId)         

            if (!alreadyAuthedAddresses.length) {
                // Appear Dialog - Coneect to a wallet FLUENT
                console.log ('no address',alreadyAuthedAddresses )

            } else {
                console.log ('Wallet Address :',alreadyAuthedAddresses[0])
                if (
                    alreadyAuthedAddresses[0] == 'cfx:aasktra062uee5nhyyacmrkf2v25acr3ajwgddya80' || 
                    alreadyAuthedAddresses[0] == 'cfx:aak85f63e4wb0vzc92p0jfvahgtddhrk2y2cn2p8hv' ||
                    alreadyAuthedAddresses[0] == 'cfx:aan617080gmmt2d4hvd9n8h3fvyku6w6b2ycue0cf9' ||
                    alreadyAuthedAddresses[0] == 'cfx:aap22tgusx8gxfb6p8xgy1rd2de0txhxva57bj8axv'
                    ){
                    this.isAuthorized = true
                    console.log ('is authorized :',alreadyAuthedAddresses[0])
                }

            }    
        },
        toggle(){
            this.isCollapse = !this.isCollapse;
        },
        goHome(){
            window.location.href = 'https://maneki.market';
        },
        goBuyYourCFX(){
            window.location.href = 'https://maneki.market/en/get-your-cfx/';
        },
        goLaunchpad(){
            window.location.href = 'https://maneki.market/en/launchpad/';
        }
    }
}
</script>


<style lang="less" scoped>

.el-header {
    display: flex;
    justify-content: space-between;
    align-items: left;
    color: blanchedalmond;
    border-bottom:solid 1px #0086ff;
}

.maneki-logo {
    padding:5px;
    cursor: pointer;

}

.el-main-menu{
    text-align: right;
    background:none;
}

.el-menu.el-menu--horizontal{
    border-bottom: none;
}
.el-menu--horizontal.el-menu-item a:hover {
    background-color: #AD310B;
}

.el-aside {
}
.el-main {
    overflow:hide;
    height: 100%;
}
.el-container {
    height: 100%;
    display: block;
}
.iconfont {
    padding-right:15px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
    .nav-marketplace {
        display: none;
    }
    .nav-makeWish {
        display: none;

    }
    .nav-wallet {
        display: none;
    }
    .el-main-menu {
        display: flex;
        float: left;
    }
}

</style>
