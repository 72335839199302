<template>
    <div class="mint_container">

        <!-- Wallet connectd -->
        <div v-if="isConnect" class="wallet-connection"  >
            <div class="token-wrap">
                <div class="cfx-balance">{{accountCFXBalance}} CFX</div>
                <div class="neko-balance">{{accountNekoBalance}} NEKO</div>
                <div class="meow-balance">{{accountNFTBalance}}</div>
                <div  style="float:left;font-size:0.6em; color:#666;"> Conflux Network : {{accAddressStorten}} </div>
            </div>
        </div>


        <!-- Wallet not connected -->
        <div v-if="!isConnect" class="wallet-connection"  >
            <div class="token-wrap">
                <div class="cfx-balance"> Conflux networks</div>
                <el-button 
                    style="font-size:1.2em; margin-top:-5px; background-color:#ffe000; color:#ff3600; border:1px solid #ff3600;" 
                    @click="connectButton()"> Connect wallet
                </el-button>
            </div>
        </div>


        
        <h1>Withdraw Auction</h1>


        <div style="background:none; margin:50px auto;">

            <ul class="list">
                <li class="item" v-if="NFTs.length=='a'">
                    <div class="item-wrap" style="border:1px solid white; ">
                        <div class="el-card-name">
                            <div> 
                             <div style="padding-top:10px;">Select a Meow</div>
                            </div>
                        </div>

                        <el-card :body-style="{ padding: '0', }">
                            <div class="image" style="height:350px; width:350px; background:black;"> </div>
                        </el-card>

                        <div class="wrap_price" style=" background:rgba(255, 255, 255, 0.8);  color:#ff4800; margin:0 auto width:300px ;height:40px;padding:20px; ">
                            <router-link :to="'/wallet'">
                            <el-button style="float:right;">  Pick now </el-button>
                            </router-link>
                        </div>


                    </div>
                </li>
                <li class="item"  v-for="(item) in NFTs" :key="item.id" >
                    <div class="item-wrap">
                        <div class="el-card-name">
                            <div> 
                                <div v-if="item.name"> {{item.name}} </div>
                                <div v-if="!item.name">Maneki 🐱</div>
                                <span style="color:#0375fc;">Guardian 招き猫 <span style="font-weight:bold">#{{item.id}}</span></span>
                            </div>
                        </div>
                        
                        <router-link :to="'/withdraw/' + item.id +'/0x0/'">
                            <el-card :body-style="{ padding: '0', }">
                                <img :src="item.url" class="image">
                            </el-card>
                        </router-link>
                        <!--
                        <div v-if="item.description" >
                            <div class="el-card-wish">{{item.description}}</div>
                        </div>  
                        -->
                        <div class="wrap_price" style=" background:rgba(255, 255, 255, 0.8);  color:#ff4800; margin:0 auto width:300px ;height:40px;padding:20px; ">
                            <div style="float:left; width:150px;height:40px; "> 
                                <span style="font-size:1em;">Price </span><br>
                                <span style="font-size:1.5em; color:black;">{{item.paymentToken}} {{ parseInt(item.currentPrice/(10**18))}} </span>
                            </div>
                            <router-link :to="'/withdraw/' + item.id +'/0x0'"  style="float:right;">
                                <el-button >  Withdraw </el-button>
                            </router-link>
                        </div>
                        <!-- <div> Time left {{ item.timeLeft }} </div> -->

                        <!--
                        <ul>
                            <li>auctionId :     {{item.auctionId}}</li>
                            <li>currentPrice :  {{item.paymentToken}} {{ parseFloat(item.currentPrice/(10**18)).toFixed(4)}}</li>
                            <li>Start Price :   {{item.paymentToken}}  {{parseFloat(item.startingPrice/(10**18)).toFixed(4)}}</li>
                            <li>End Price :     {{item.paymentToken}}  {{ parseFloat(item.endingPrice/(10**18)).toFixed(4) }}</li>
                            <li>Start at :      {{item.startAt}}  </li>
                            <li>duration :      {{item.duration}}</li>
                            <li>  {{item.timeLeft}} left</li>
                            <li>  {{ item.timeLeft/3600 }} left</li>
                        </ul>
                        -->
                    </div>

                </li>

                <div style="clear:both;"></div>
            </ul>
        </div>


    



    </div>
</template>

<script>

import abi_collectible from '../web3/abi_collectible';
import abi_exchange from '../web3/abi_exchange';
import abi_neko from '../web3/abi_neko';


//Conflux SDK
const { Conflux } = require('js-conflux-sdk');
const conflux = new Conflux({
  url: 'https://main.confluxrpc.com',
  networkId: 1029,
});

console.log(conflux.version);
const provider = window.conflux

//connect Collectibles Contract 
const contract_collectibles =  conflux.Contract({abi:abi_collectible, address:'cfx:acedfgnc6tp0g8t2yy35gmr4pw4cjk7sgus4fmktnn'});
const contract_neko = conflux.Contract({abi:abi_neko, address:'cfx:acejgycpayveh1heb94c1yrdrd1y5t9mhpbmatdsx3'});
const contract_marketplace = conflux.Contract({abi:abi_exchange, address:'cfx:acdtn35x2bhabgknjambku8ztr9sp2gmh2ppr9u5d1'});
// connect Neko Contract
// const contract_USDT = conflux.Contract({abi:abi_neko, address:'cfxtest:acepe88unk7fvs18436178up33hb4zkuf62a9dk1gv'});



//const path_TxLogURL = 'https://confluxscan.io/transaction/';
const path_Metadata = 'https://md.maneki.market/token/';
//const path_Image = 'https://m.maneki.market/';


    
export default {
    data(){
        return{
            isConnect: false,
            accountCFXBalance : 0,
            accountNekoBalance : 0,
            accountNFTBalance : 0,
            accAddress : '0x0',
            accAddressStorten : '0x0',
            activeIndex: '1',
            contractInstance:null,
            account:null,
            isCollapse:false,
            tokenSupply:0,
            name:null,
            loading : true,
            display :{
                isConnect : false,
            },
            // NFT 
            totalGuardian :0,
            NFT : {
                owned:0,
                supply:0,
                balance:0
            },
            NFTs :[],
            search: '',
            contract :{
                collectibles:null,
                exchange :null
            },
            dialog :{
                approveToExchange : false,
                offerForm : false,
                sendGift : false,
            },
            approvalForm: {
                tokenID: ''
            },
            offerForm: {
                tokenID: '',
                price: '',
                currency:'',
                buyer:''
            },
            giftForm : {
                tokenID: '',
                receiverAddr:''
            },
            formLabelWidth: '120px',
            coinBalance : [],
            coinPrice :'',
            web3:null,

        }
    },
    mounted() {
        this.initWallet();
 
        provider.on('accountsChanged', accounts => {
            this.accAddressStorten= accounts[0]
            this.initWallet()
            this.isConnect = true
            this.dialogActivate = false
            console.log('accountsChanged = ', accounts)
        })
 
        provider.on('chainChanged', chainId => {
            console.log('chainChanged,  = ', chainId)
            provider.request({method: 'cfx_netVersion'}).then(networkId => {
            console.log('networkId,  = ', networkId)
            })
        })

        this.getAuctions();
 
    },
    methods :{

       initWallet(){
            
            // Fluent Extention Installed?
            if (window?.conflux?.isFluent) {
                // Installed
                this.walletInitialized()
            } else {
                //Fluent Not install, Request to make installation
                console.log('Fluent is not connected, please try refresh this page or restart your browser.')
                // Appear dialog , LINK - request install Fluent 
                //this.dialogInstall = true
            }
        },
        connectButton(){
            
            // Fluent Extention Installed?
            if (window?.conflux?.isFluent) {
                // Installed, Request authorized with wallet
                //this.walletInitialized()
                this.dialogActivate = true

            } else {
                //Fluent Not install, Request to make installation
                console.log('Fluent is not connected, please try refresh this page or restart your browser.')
                // Appear dialog , LINK - request install Fluent 
                this.dialogInstall = true
            }
        },
        async authorizedWallet(){
            console.log('Requesting account')
            provider
            .request({
            method: 'cfx_requestAccounts',
            })
            .then(this.walletInitialized())
            .catch(error => console.error('error', error.message || error))
        },
        async walletInitialized() {
            console.log('Fluent Wallet Connected')

            provider.request({method: 'wallet_getFluentMetadata'}).then(({version}) => {
                console.log('Fluent version :',version);
            })

            const [chainId, networkId, alreadyAuthedAddresses] = await Promise.all([
            provider.request({method: 'cfx_chainId'}),
            provider.request({method: 'cfx_netVersion'}),
            provider.request({method: 'cfx_accounts'}),
            ])

            console.log('initialized')
            console.log('chainId :',  chainId)
            console.log('networkId: ', networkId)          
            
            if (!alreadyAuthedAddresses.length) {
                // Appear Dialog - Coneect to a wallet FLUENT
                console.log ('no address',alreadyAuthedAddresses )

            } else {
                console.log ('Wallet Address :',alreadyAuthedAddresses[0])
                this.accAddress = alreadyAuthedAddresses[0]
                this.accAddressStorten  = alreadyAuthedAddresses[0]
                this.account = alreadyAuthedAddresses[0]
                this.isConnect = true
                this.fetchData()
            }            


        },

        fetchData(){
                this.getNekoTokenBalance();
                this.getCFXBalance();
                this.getNFTBalance();
                //this.getNFTDetails(this.$route.params.id);
        },
        async getAuctions() {
            contract_marketplace.auctionId().call().then((lastAuctionID) => {

                //for( let i= lastAuctionID - factor ; i >= lastAuctionID - factor - 50 ; i--){
                for( let i= lastAuctionID ; i >= lastAuctionID - 150 ; i--){
                    console.log (i);

                        // get current auction details
                        contract_marketplace.getAuctionByAuctionId(i).call().then((res) => {


                            // filter by : seller
                            //console.log('res[1] :', res[1]);
                            //console.log('this.account :', this.account);
                            if(res[1]==this.account){
                                console.log('Auction valid :', res);


                                //if(res[0]!='-32603'){
                                //0 auction.id,
                                //1 auction.seller,
                                //2 auction.tokenId,
                                //3 auction.paymentToken,
                                //4 auction.startingPrice,
                                //5 auction.endingPrice,
                                //6 auction.duration,
                                //7 auction.startedAt

                                var startUnixTime = new Date(res[7].toString()*1000);

                                let nowUnixTime = Math.floor(Date.now() / 1000)

                                let timeLeft = nowUnixTime - res[7].toString()

                                console.log('start datetime :', startUnixTime.toLocaleString());
                                console.log('startUnixTime :', res[7].toString());
                                console.log('nowUnixTime :', nowUnixTime);
                                console.log('timeLeft :', timeLeft);

                                this.pumpNekos(
                                    res[0].toString(), // _auctionId
                                    res[1],            // _seller
                                    res[2].toString(), // tokenId
                                    this.determinePaymentToken(res[3]),    // _paymentToken
                                    res[4].toString(), // _startingPrice
                                    res[5].toString(), // _endingPrice
                                    res[6].toString(), // _duration
                                    startUnixTime, // _startAt
                                    0,      // _currentPrice
                                    timeLeft           // _timeLeft
                                    );
                            }
                                


                            }).catch((err) => {
                            console.log(err, 'err');
                            });


                }
            })
        },


        pumpNekos(_auctionId, _seller,  _tokenId, _paymentToken, _startingPrice, _endingPrice, _duration, _startAt, _currentPrice, _timeLeft ){

                // retrive NFT Details
                const axios = require('axios');
                axios.get( path_Metadata + _tokenId)
                .then((metadata) => {

                    this.NFTs.push({
                        id: _tokenId,
                        power: metadata.data.attributes[3].value,
                        DNA: metadata.data.art_dna,
                        url: metadata.data.image,

                        name : metadata.data.name,
                        description : metadata.data.description,
                        image : metadata.data.image,
                        gamma : metadata.data.gamma,

                        auctionId : _auctionId,
                        seller : _seller,
                        paymentToken : _paymentToken,
                        startingPrice : _startingPrice,
                        endingPrice : _endingPrice,
                        duration : _duration,
                        currentPrice : _currentPrice,
                        startAt : _startAt,
                        timeLeft : _timeLeft

                    });

                }).catch((err) => {
                    console.log(err, 'err');
                });
                console.log ("NFTs :",this.NFTs)


        },
        determinePaymentToken (_tokenAddress){
            let lable = 0;
            if(_tokenAddress == "cfxtest:acepe88unk7fvs18436178up33hb4zkuf62a9dk1gv"){
                lable = 'USDT'
            } else if (_tokenAddress == "cfxtest:aca9u5eu3zvmjv6gdudvf21g9uj2cm60upya76k4e4"){
                lable = 'NEKO'
            } else {
                lable = 'CFX'
            }
            return lable
        },
        async getNekoTokenBalance (){      
            await contract_neko.balanceOf(this.accAddress).then((res) => {
                console.log('Balance $NEKO:', (res.toString()/1e18).toFixed(2));
                this.accountNekoBalance = (res.toString()/1e18).toFixed(2);
            }).catch((err) => {
                console.log(err, 'err');
            });
        },

        async getCFXBalance(){
                await conflux.getBalance(this.accAddress).then((res) => {
                console.log('$CFX :', (res.toString()/1e18).toFixed(2));
                this.accountCFXBalance = (res.toString()/1e18).toFixed(2);
            }).catch((err) => {
                console.log(err, 'err!!');
            });
        },

        async getNFTBalance (){
            const balance = await contract_collectibles.balanceOf(this.accAddress);
            
            this.accountNFTBalance = balance[0];
            console.log('Total Guardian Owned:',balance); 
        },


    }
}
</script>

<style scoped>
h1{
    width: 25%;
    font-size: 2em;
    color:rgba(255, 255, 0, 0.7);
    margin:0 auto;
    font-weight: 100;
    margin-bottom: 30px;
    text-align: center;
}

.wallet-connection{
    width: 460px;
    text-align: right;
    color: #c6c6c6;
    float:right;
    margin-right:30px;
}


.token-wrap{
    width: 460px;
    height: 25px;
    padding:20px;
    margin-top:-20px;
    background: #3f3f3f;
    border-radius: 0 0 10px 10px;
    background:rgba(0, 0, 0, .4); 
    border:solid 1px #0086ff;
}
.cfx-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-cfx.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:140px;
    float:left;
    color:white;
}
.neko-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-neko.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:140px;
    color:#fcee21;
    float:left;
}
.meow-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-meow.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:60px;
    color:#fcee21;
    float:left;
}
.Asset-Summery {
    float: left;
    min-width: 350px;
    background:rgba(128, 255, 209, .2); 
    margin:15px 20px;
    padding: 5px 10px;
}
.list {
    top:50px;
    width: 91%;
    padding:0;
    margin:0 auto;
}
.item {
    width: 250px;
    float:left;
    list-style: none;
    min-width: 350px;
    min-height: 500px;
    margin:50px 20px;
    margin-right:50px;
}
.item-wrap {
    border: 1px solid black;
    width: 250px;
    padding: 10px;
    background:rgba(0, 0, 0, .4); 
    border-radius: 10px;
    min-width: 350px;
    min-height: 350px;
}
.el-card-wish{
    background-image: url("../../src/assets/images/icon-wish.png");
    background-repeat: no-repeat;
    background-position: top left; 
    padding-top: 5px;
    margin-top:5px;
    padding-left:60px;
    color:white; 
    font-size:1.2em;
    min-height: 100px;
}
.el-card-name{
    background-image: url("../../src/assets/images/icon-meow.png");
    background-repeat: no-repeat;
    background-position: top left; 
    padding-left:60px;
    color:white; 
    font-size:1.2em;
    min-height: 60px;
}


.alert-msg {
    color: yellow; 
    padding:20px; 
    width:40%; 
    margin:0 auto; 
    border:0px solid red; 
    float:right; 
    background:rgba(0, 0, 0, .4);
}
.alert-msg a:link{
    color : white;
    text-decoration:none;
    font-weight: 600;
}

.alert-msg a:visited {
    color : white;
    text-decoration:none;
    font-weight: 600;
}
.alert-msg a:hover {
    color: hotpink;
}
</style>