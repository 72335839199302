<template>
    
    <div class="mint_container">

        <!-- Wallet connectd -->
        <div v-if="isConnect" class="wallet-connection"  >
            <div class="token-wrap">
                <div class="cfx-balance">{{accountCFXBalance}} CFX</div>
                <div class="neko-balance">{{accountNekoBalance}} NEKO</div>
                <div class="meow-balance">{{accountNFTBalance}}</div>
                <div  style="float:left;font-size:0.6em; color:#666;"> Conflux Network : {{accAddressStorten}} </div>
            </div>
        </div>


        <!-- Wallet not connected -->
        <div v-if="!isConnect" class="wallet-connection"  >
            <div class="token-wrap">
                <div class="cfx-balance"> Conflux networks</div>
                <el-button 
                    style="font-size:1.2em; margin-top:-5px; background-color:#ffe000; color:#ff3600; border:1px solid #ff3600;" 
                    @click="connectButton()"> Connect wallet
                </el-button>
            </div>
        </div>


        <h1> Loop Mint </h1> 

        <!-- Gashapon -->

        <el-card style="margin-top:100px;box-shadow:none;">
        <!-- Form - Part 1, Guadian  -->
        <el-form class ="gashapon" :model="form"  v-if="display.isGuardian" :rules="wishRules" ref="guardianForm">
            <el-form-item prop="guardian" style="height:95px;">
                <router-link :to="'/wallet'">
                 <el-image
                    style="max-width:195px; max-height:95px; padding:0 46px; margin-top:25px;"
                    :src="newNFT.guardianIMG"
                    >

                    <div slot="placeholder" class="image-slot">
                        Loading<span class="dot">...</span>
                    </div>
                    <div slot="error" class="image-slot" >
                        <div class="el-icon-picture"></div>
                    </div>
                
                </el-image>

                </router-link> 
            </el-form-item> 

            <el-form-item  style="padding-top:130px; text-align:center;">
                <img class="btn_twister" src="../../src/assets/images/twister.png" v-on:click="gotoWish" />
            </el-form-item>
      

        </el-form>

        <!--
        <span style="color:white;">
        <h1> Make a wish! </h1>

        <h1> Total Neko Collectibles Token (NCT): {{NFT.supply}} </h1>
        <div> Collectible Pool balance : {{poolBalance}} </div>
        <div> Collector Wallet address : {{account}}</div>
        <div> Collector $NEKO : {{this.accountNekoBalance}}</div>
        <div> Neko Collectibles Token (NCT) : {{NFT.balance}}</div>
        <div> LIST of NCT : {{NFT.owned}}</div>

        </span>
        -->

        </el-card>


        <el-dialog :visible.sync="dialog.mint">

            <div class="dialog-input-wrap">
                <img src="../../src/assets/images/sending.gif" style="width:500px; height:350px; margin:0 auto;"/>
                <div class ="dialog-footer-msg"> Minting ... <br>
                {{nowAddress}}<br>
                Status : {{nowSequance}} / {{ form.loop}}
                </div>
            </div>
        </el-dialog>


        <el-dialog :visible.sync="dialog.wish" class="dailog_wish">
            <!-- Form - Part 2, Wish  -->
            <el-form :model="form" :rules="wishRules" ref="wishForm">
                <div class="dialog-input-wrap">
                    
                <div class="dialog-content">
                    <div style="color:white; font-size:1.8em; padding-top:50px; padding-left:20px;padding-bottom:20px; "> 
                        <span style="color:yellow; font-size:0.7em;">選択した言語で願い事をする</span> 
                        <br> Loop Minting <br>
 
                        <el-form-item  prop="receiver"  style="width:400px; margin: 0 auto; height: 50px;">
                            <el-input v-model="form.wallet" placeholder="Conflux core address" ></el-input>
                        </el-form-item>
                        <el-form-item  prop="generation"  style="width:400px; margin: 0 auto; height: 50px;">
                           <el-input v-model="form.generation" placeholder="Generation" ></el-input>
                        </el-form-item>

                        <el-form-item  prop="loop" style="width:400px; margin: 0 auto;height: 50px;" >
                          <el-input v-model="form.loop"  placeholder="Mint Quantity" ></el-input>
                        </el-form-item>
                        <span style="color:yellow; font-size:0.5em;"> Status : {{nowSequance}} / {{ form.loop}}</span>
                    </div>

                    <el-form-item  class="dialog-footer">
                        <el-button type="gashapon" @click="kickstartBatchMint()">    Ok    </el-button>
                    </el-form-item>
                </div>
                </div>

            </el-form>
        </el-dialog>



        <!-- Form - Part 3, name  -->
        
        <el-dialog :visible.sync="dialog.name" class="dailog_name">
            <el-form :model="form"  :rules="nameRules" ref="nameForm">
                <div class="dialog-input-wrap">

                <div class="dialog-content">
                <div style="color:white; font-size:1.8em; padding-top:100px; padding-left:80px;padding-bottom:20px; ">
                    <span style="color:yellow; font-size:0.7em;">ニャー ...</span><br>
                    Meooow ....</div>
                <el-form-item  prop="name"  style="width:350px; margin: 0 auto; height:200px">
                    <el-input v-model="form.name" placeholder="what's your meow name?" ></el-input>
                </el-form-item>
                </div>

                <el-form-item class="dialog-footer" >
                    <el-button type="gashapon" @click="gotoPayment('nameForm')">    Ok    </el-button>
                </el-form-item>
                </div>

            </el-form>
        </el-dialog>

        <el-dialog :visible.sync="dialog.gamma">
            <div class="dialog-input-wrap">
                <div class="dialog-content">
                <div style="color:white; font-size:1.8em; text-align:center; padding-top:100px; padding-bottom:20px; ">
                    <span style="color:yellow; font-size:0.7em;">願い事を送るための守護者としてニャーが必要</span><br>
                    Required a Meow as <br>the guardian for wish sending</div>
                </div>
                <div style="margin:0 auto; height:30px; width:330px;">
                <router-link :to="'/wallet'"> 
                    <el-button  type="text" style=" width:100px; border:1px solid red;"> I Have! </el-button> 
                </router-link>
                <router-link :to="'/marketplace'"> 
                    <el-button icon="el-icon-cart"  style="margin-left:30px; width:200px; "> Buy from marketplace</el-button> 
                </router-link>
                </div>
            </div>
        </el-dialog>


        <!-- Form - Part 4, Payment  -->
        <el-dialog :visible.sync="dialog.payment" class="dailog_payment">
            <el-form :model="form"  :rules="paymentRules" ref="paymentForm">
                    
                <div class="dialog-input-wrap">

                    <div class="dialog-content">
                    <div style="color:white; font-size:1.8em; text-align:center; padding-top:100px; padding-bottom:20px; ">
                        <span style="color:yellow; font-size:0.7em;">であなたの願いをミント</span><br>
                        Mint your wish with</div>
                        

                        <el-form-item  prop="walletAddr"  style="width:350px; margin: 0 auto; height:200px">
                            <el-input v-model="form.walletAddr" placeholder="Wallet address" ></el-input>
                        </el-form-item>
                    </div>


                    <el-form-item class="dialog-footer">
                        <el-button type="gashapon" @click="buyNFT('paymentForm')"> Ok </el-button>
                    </el-form-item>
                </div>


            </el-form>
        </el-dialog>


        <!-- Form - Part 4, painting  -->
        <el-dialog :visible.sync="dialog.painting">
            
            <div class="dialog-input-wrap" style="color:white;">
                <img src="../../src/assets/images/painting.gif" style="width:500px; height:350px; margin:0 auto;"/>

                <div class ="dialog-footer-msg">
                    Painting NFT : {{nowSequance}} | {{nowAddress}} 
                    <br>
                    {{ newNFT.name }} (招き猫#{{ newNFT.id }}) : {{ newNFT.wish }} 
                    <br>
                    Maneki Power {{ newNFT.power.toLocaleString() }}
                </div>
            </div>


        </el-dialog>

        <!-- Form - Part 5, painting  -->
        <el-dialog :visible.sync="dialog.success">
            
            <div class="dialog-input-wrap" style="color:white; text-align:center; padding-bottom:50px;">

                <div class="dialog-content">
                <div style="margin: 0 auto; color:white; font-size:1.8em; text-align:center; padding-top:100px; padding-bottom:20px; width:350px; ">
                    
                    <span style="color:yellow; font-size:0.7em;">Maneki-Meowは、ユニークなNFTアート作品を通じてあなたの祝福と願いを届けます</span><br>
                    Distributed : {{nowAddress}}  <br>
                    squance : {{nowSequance}} <br>
                </div>
                </div>

                <router-link :to="'/token/' + newNFT.id+ '/0x0'"> 
                    <el-button type="gashapon"  > Peep now!</el-button>
                </router-link>
            </div>


        </el-dialog>


        <el-dialog title="Step 1" :visible.sync="dialogApprove">
        <el-form :model="form">
            <span>Need 
                {{payment.tokenSymbol }} 
                {{payment.tokenQuantity/10**18}} to Mint a NFT</span>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="approvePayment(payment.tokens[form.payment].tokenQuantity)">Approve</el-button>
        </div>
        </el-dialog>


        <el-dialog title="Step 3" :visible.sync="dialogMint">
            <el-form :model="form">
                <span>Let's mint your NFT</span>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="payByERC20Token (form.guardian)">Confirm</el-button>
            </div>
        </el-dialog>



        <!-- Download wallet -->
        <el-dialog  :visible.sync="downloadWallet">

            <div class="dialog-input-wrap">


                <div class="dialog-content" style="height:250px;">
                    <div style="margin: 0 auto; color:white; font-size:1.6em; text-align:center; padding-top:50px; padding-bottom:5px; width:350px; ">
                        <span style="color:yellow; font-size:0.8em;">Maneki-MeowをConfluxウォレットで<br>安全にチェーンに保管する</span><br>
                        Store Maneki-Meow <br>safely on-chain with
                        <br>
                        <img  style="margin-top:30px; width:220px; background:white; border-radius:5px; padding:20px;" src="https://fluentwallet.com/assets/logo.b3425fdb.svg"  />
                    </div>
                </div>

                <div slot="footer" class="dialog-footer">
                    <el-button style="font-size:1.2em; margin-top:30px; background-color:#ffe000; color:#ff3600; border:1px solid #ff3600; "  > 
                        <a href="https://chrome.google.com/webstore/detail/fluent/eokbbaidfgdndnljmffldfgjklpjkdoi" target="_blank">
                            Free downlaod
                        </a>
                    </el-button>
                        <div style="color:#CCC;padding-top:5px;"> for Chrome </div>
                    <div style="margin-top:60px;"> 
                        New to Conflux? 
                        <a href="https://fluentwallet.com/" target="_blank"> Learn more about wallets</a>
                    </div> 
                </div>

            </div>
        </el-dialog>

      <!-- Install Fluent -->
        <el-dialog  :visible.sync="dialogInstall" :close-on-click-modal="false" >
            <div class="dialog-input-wrap">
                <div class="dialog-content">
                    <div style="color:white; font-size:1.8em; padding-top:120px; padding-bottom:20px; width:350px; margin:0 auto; "> 
                        <span style="color:yellow; font-size:0.7em;"></span> Connect to a wallet
                    </div>
                    <div class="walletButton">
                    <a href="https://fluentwallet.com/" target="_blank" >
                        <div style="width:250px; height:35px; color:black; font-size:1.7em; font-weight:100; float:left; "> Install Fluent Wallet </div>
                        <img style="width:35px; float:left; " src="../../src/assets/images/fluent-logo.png" />
                    </a>
                    </div>
                    <div style="color:#666; font-size:1em; padding-top:80px; width:350px; margin:0 auto; "> 
                        New to Conflux? <a href="https://fluentwallet.com/" target="_blank" style="color:#666;"> Learn more about wallet </a>
                    </div>
                </div>
            </div>
        </el-dialog>

      <!-- Activate address Fluent -->
        <el-dialog  :visible.sync="dialogActivate" :close-on-click-modal="false" >
            <div class="dialog-input-wrap">
                <div class="dialog-content">
                    <div style="color:white; font-size:1.8em; padding-top:120px; padding-bottom:20px; width:350px; margin:0 auto; "> 
                        <span style="color:yellow; font-size:0.7em;"></span> Connect to a wallet
                    </div>
                    <div class="walletButton" @click="authorizedWallet()">
                        <div style="width:250px; height:35px; color:black; font-size:1.7em; font-weight:100; float:left; "> Fluent Wallet </div>
                        <img style="width:35px; float:left; " src="../../src/assets/images/fluent-logo.png" />
                    </div>
                    <div style="color:#666; font-size:1em; padding-top:80px; width:350px; margin:0 auto; "> 
                        New to Conflux? <a href="https://fluentwallet.com/" target="_blank" style="color:#666;"> Learn more about wallet </a>
                    </div>
                </div>
            </div>
        </el-dialog>

        
    </div>
</template>

<script>


// Contract : Collectibles 
import abi_collectible from '../web3/abi_collectible';
import abi_payment from '../web3/abi_payment';
import abi_neko from '../web3/abi_neko';


import bytecode_payment from '../web3/bytecode_payment';

//Conflux SDK
//const { Conflux , format } = require('js-conflux-sdk');
const { Conflux } = require('js-conflux-sdk');
const conflux = new Conflux({
  url: 'https://main.confluxrpc.com',
  networkId: 1029,
});

console.log(conflux.version);
const provider = window.conflux


// add private key to local wallet 
const PRIVATE_KEY = '0x4a85466e328636c6758523f03b5bf06185b4ba0382e663fe72351be44f30809d';
const sender = conflux.wallet.addPrivateKey(PRIVATE_KEY);

const contract_collectibles =  conflux.Contract({abi:abi_collectible, address:'cfx:acedfgnc6tp0g8t2yy35gmr4pw4cjk7sgus4fmktnn'});
const contract_neko = conflux.Contract({abi:abi_neko, address:'cfx:acejgycpayveh1heb94c1yrdrd1y5t9mhpbmatdsx3'});
const contract_payment = conflux.Contract({abi:abi_payment, address:'cfx:acda4ucxkz3yt6cng8e7972xdn3axdag0jy0xwh0rv', bytecode:bytecode_payment});


const path_Image = 'https://m.maneki.market/';

//const path_TxLogURL = 'https://testnet.confluxscan.io/transaction/';
//const path_Metadata = 'https://t2metadata.maneki.market/token/'



export default {
  name: 'App',
  data() {
    return {
        nowAddress :0,
        nowSequance : 0,
        newGas : true,
        winnerAddress : [
            'cfx:aajv26pz8bjb1cktckmn5rm10efs6b7yrasgc9f8xs',
            'cfx:aajv26pz8bjb1cktckmn5rm10efs6b7yrasgc9f8xs',
        ],
        wishlist : [
            '黑猫白猫会招财一定是好猫',
            '幸運を祈る！運がよくなる宇宙からのサイン',
            'お誕生日おめでとうございます！',
            '旅行を気を付けて！',
            '平和の愛と幸福',
            'トニカクカワイイ',
            '#BUIDL ManekiMeow to the #Moon',
            '#HODL NEKO to the moon',
            '#HODL CFX to the moon',
            'When Lambo? I will get my Lambo soon',
            '#WHALE... you dump, I will pump!',
            'Happy birthday ~!',
            'Good Health, Happiness, Prosperity and Success in life',
            'An abundance of prosperity, joy and success',
            'Be Rich With Crypto',
            'Stay strong, stay healthy',
            '1 Maneki-Meow = 1 BTC',
            'スーパーラッキーデー!',
            'LET\'S HIT THE NEW ATH TODAY!',
            'Pump it!',
            'Bullish Day!',
            '1 Meow = 100,000 NEKO',
            'Travel around the world',
            'がんばってね',
            'あなたは最高です',
            '你是棒棒的...要加油喔',
            '别再卖萌了',
            'Marry me',
            '모든 소원이 이루어지길 바랍니다!',
            'Alles Gute zum Geburtstag!',
            '生日快乐！',
            'Selamat Hari Jadi!',
            'Selamat ulang tahun!',
            'З Днем Народження!',
            'С днем ​​рождения!',
            'பிறந்தநாள் வாழ்த்துக்கள்!',
            'Chúc mừng sinh nhật!',
            'Gelukkige verjaarsdag!',
            'عيد مولد سعيد!',
            'Všechno nejlepší k narozeninám!',
            'जन्मदिन की शुभकामनाएं!',
            'ပျော်ရွင်ဖွယ်ရာမွေးနေ့!',
            'Doğum günün kutlu olsun!',
            'Maligayang kaarawan!',
            'สุขสันต์วันเกิด!'
        ],
        namelist : [
            'Nyan',
            'Yoshi',
            'Haruki',
            'Kenzo',
            'Momo',
            'Yuki',
            'Fuwafuwa',
            'Tadeo',
            'Yuuma',
            'Adzuki',
            'Taro',
            'Sota',
            'Fuku',
            'Yuki',
            'Yukigafuru',
            'Shiroi',
            'Raisupati',
            'Kumo',
            'Shirasagi',
            'Mashumaro',
            'Shinju',
            'Kaiko',
            'Hakahu',
            'Wata no kyū',
            'Tofu',
            'Hai',
            'Sumōkī',
            'Yami',
            'Karasu',
            'Daku',
            'Kage',
            'Kazan',
            'Suitopi',
            'Mei',
            'Kirei',
            'Chisai tori',
            'Shuga',
            'Maru',
            'Fuji',
            'Chīsana',
            'Chiisai',
            'Kei',
            'Doki doki',
            'Aiko',
            'Koro',
            'Kichōna',
            'Akachan',
            'Chiyo',
            'Nakano',
            'Yua',
            'Mio',
            'Eiji',
            'Himari',
            'Hana',
            'Makoto',
            'Danuja',
            'Kiyo',
            'Yamato',
            'Tatsuki',
            'Taeko',
            'Hiroshi',
            'Kimi',
            'Hitoshi',
            'Akio',
            'Katoro',
            'Senshi',
            'Toshiro',
            'Tadaaki',
            'Ichiro',
            'Ryuu',
            'Masanori',
            'Benjiro',
            'Kento',
            'Shohei',
            'Mokomichi',
            'Rola',
            'Nozomi',
            'Haruna',
            'Takashi',
            'Naomi',
            'Hotaru',
            'Sakura',
            'Kawa',
            'Hoshi',
            'Yuri',
            'Ohisama',
            'Aito',
            'Rina',
            'Mori',
            'Amaya',
            'Kuuki',
            'Rini',
            'Daichi',
            'Nami',
            'Shino',
            'Kanaye',
            'Toshiko',
            'Ichika',
            'Shiori',
            'Kisho',
            'Nen',
            'Teru',
            'Tamako',
            'Kaori',
            'Setsuko',
            'Miami'
        ],
        isConnect: false,
        accountCFXBalance : 0,
        accountNekoBalance : 0,
        accountNFTBalance : 0,
        accAddress : '0x0',
        accAddressStorten : '0x0',
        dialogInstall : false,
        dialogActivate : false,


        web3: null,
        account:null,
        poolBalance:0,
        selected : 0,
        display :{
            isConnect : false,
            isGuardian : true,
            isName : false,
            isWish : true,
            isPayment : false,
        },
        NFT : {
            owned:0,
            supply:0,
            balance:0
        },
        contract :{
            collectibles:null,
            payment:null,
            neko:null,
            ERC20:null
        },
        payment : {
            tokens : [],
            key : 0,
        },
        dialog :{
            mint :false,
            success: false,
            gamma : false,
            wish : false,
            name : false,
            payment : false,
            painting : false,
        },
        dialogTableVisible: false,
        dialogApprove : false,
        dialogMint:false,
        form: {
            name: '猫の守護者',
            guardian: '',
            payment: '',
            wish: 0,
            walletAddr:'cfx:aan617080gmmt2d4hvd9n8h3fvyku6w6b2ycue0cf9',
            loop : 1,
            wallet : '',
            generation: '',
        },
        newNFT : {
            id: '',
            img: '',
            wish: '',
            name: '',
            guardian : '',
            guardianIMG :'',
            DNA: '',
            gammaID: '',
            power: '',
            refCount: '',
        },
        wishRules: {
                wish: [
                    { required: true, message: 'Please make a wish', trigger: 'blur' },
                    { min: 0, max: 110, message: 'Message within 5 to 110 charaters', trigger: 'blur' }
                ],
        },    
        nameRules: {
                name: [
                    { required: true, message: 'Give a name', trigger: 'blur' },
                    { min: 0, max: 20, message: 'Message within 3 to 20 charaters', trigger: 'blur' }
                ],
        },  
        paymentRules : {
                payment: [
                    { required: true, message: 'select a payment token', trigger: 'change' }
                ]
        },          
        formLabelWidth: '120',
        paymentSelected : {},
        downloadWallet: false,
    };
  },
  created() {
        this.form.payment = 0;
  },
  mounted() {
        this.initWallet();
 
        provider.on('accountsChanged', accounts => {
            this.accAddressStorten= accounts[0]
            this.initWallet()
            this.isConnect = true
            this.dialogActivate = false
            console.log('accountsChanged = ', accounts)
        })
 
        provider.on('chainChanged', chainId => {
            console.log('chainChanged,  = ', chainId)
            provider.request({method: 'cfx_netVersion'}).then(networkId => {
            console.log('networkId,  = ', networkId)
            })
        })

        this.initPaymentMethod()

        console.log('Machine key:', this.generateMachineInput());



  },
  methods:{

        initWallet(){
            
            // Fluent Extention Installed?
            if (window?.conflux?.isFluent) {
                // Installed
                this.walletInitialized()
            } else {
                //Fluent Not install, Request to make installation
                console.log('Fluent is not connected, please try refresh this page or restart your browser.')
                // Appear dialog , LINK - request install Fluent 
                //this.dialogInstall = true
            }
        },
        connectButton(){
            
            // Fluent Extention Installed?
            if (window?.conflux?.isFluent) {
                // Installed, Request authorized with wallet
                //this.walletInitialized()
                this.dialogActivate = true

            } else {
                //Fluent Not install, Request to make installation
                console.log('Fluent is not connected, please try refresh this page or restart your browser.')
                // Appear dialog , LINK - request install Fluent 
                this.dialogInstall = true
            }
        },
        async authorizedWallet(){
            console.log('Requesting account')
            provider
            .request({
            method: 'cfx_requestAccounts',
            })
            .then(this.walletInitialized())
            .catch(error => console.error('error', error.message || error))
        },
        async walletInitialized() {
            console.log('Fluent Wallet Connected')

            provider.request({method: 'wallet_getFluentMetadata'}).then(({version}) => {
                console.log('Fluent version :',version);
            })

            const [chainId, networkId, alreadyAuthedAddresses] = await Promise.all([
            provider.request({method: 'cfx_chainId'}),
            provider.request({method: 'cfx_netVersion'}),
            provider.request({method: 'cfx_accounts'}),
            ])

            console.log('initialized')
            console.log('chainId :',  chainId)
            console.log('networkId: ', networkId)          
            
            if (!alreadyAuthedAddresses.length) {
                // Appear Dialog - Coneect to a wallet FLUENT
                console.log ('no address',alreadyAuthedAddresses )

            } else {
                console.log ('Wallet Address :',alreadyAuthedAddresses[0])
                this.accAddress = alreadyAuthedAddresses[0]
                this.accAddressStorten  = alreadyAuthedAddresses[0]
                this.isConnect = true
                this.fetchData()
            }            


        },

        fetchData(){
                this.getNekoTokenBalance();
                this.getCFXBalance();
                this.getNFTBalance();
                this.getNFTDetails(this.$route.params.guardian);
                
        },

        async getNekoTokenBalance (){      
            await contract_neko.balanceOf(this.accAddress).then((res) => {
                console.log('Balance $NEKO:', (res.toString()/1e18).toFixed(2));
                this.accountNekoBalance = (res.toString()/1e18).toFixed(2);
            }).catch((err) => {
                console.log(err, 'err');
            });
        },

        async getCFXBalance(){
                await conflux.getBalance(this.accAddress).then((res) => {
                console.log('$CFX :', (res.toString()/1e18).toFixed(2));
                this.accountCFXBalance = (res.toString()/1e18).toFixed(2);
            }).catch((err) => {
                console.log(err, 'err!!');
            });
        },

        async getNFTBalance (){
            const balance = await contract_collectibles.balanceOf(this.accAddress);
            
            this.accountNFTBalance = balance[0];
            console.log('Total Guardian Owned:',balance); 
        },

        async getNFTDetails(_id){
            if(_id > 0){
            await contract_collectibles.Nekos(_id).call().then((res) => {

                this.NFT.id           = _id;
                this.NFT.power        = res[0]; 
                this.NFT.DNA          = res[1];
                this.NFT.refCount     = parseInt(res[2]);
                this.NFT.gamma        = parseInt(res[3]);
                this.NFT.piggyBank    = parseInt(res[4].toString()/10**18).toLocaleString() ;
                this.NFT.lastPrice    = parseInt(res[5].toString()/10**18).toLocaleString() ;

                console.log('Blessing used: ', parseInt(res[2]));

                this.getGuardian(this.NFT.id)
                
            })    
            }

        },

        getGuardian (_id){
            this.newNFT.guardian = _id;
            this.newNFT.guardianIMG = path_Image + _id + '.gif'
            this.form.guardian = _id;

            console.log('Guardian id : ', _id);
        },



   
    gotoGuardian () {
            this.display.isGuardian = true;
    },
    gotoWish () {
        //if (this.form.guardian>0){ 
            this.display.isGuardian = true;
            this.dialog.wish = true;
        //} else {
        //    this.dialog.gamma = true;
        //}
    },
    gotoName (form) {
        this.$refs[form].validate((valid) => {
            if (valid) {
                this.dialog.wish = false;
                this.dialog.name = true;
            }
        })
    },
    gotoPayment (form) {
        this.$refs[form].validate((valid) => {
            if (valid) {
                this.dialog.name = false;
                this.dialog.payment = true;
            }
        })
    },
    async buyDirect(_buyer,_machine,_refNekoId){
        console.log('inner acc :', this.account);
        await this.contract.collectibles.methods.mintCollectible(_buyer,_machine,_refNekoId).send({
            from: this.account,
        });
                
    },

    generateMachineInput (){

        // 3D = 999  ORIGIN
        // 4D = 0001 PAINTER : Source Code that use to generate art work 0001
        // 4D = 0001 SERIES  : New Designs added by batch 0001
        // 11D = BIRTHDAY 01610117854

        var machineInput1 = "999000100010";
        var machineInput2 = "99900010001";
        var machineInput = null;

        var localtime = Math.round((new Date()).getTime() / 1000);
        var tokyo_offset = 540*60;
        var tokyoTime = (localtime + tokyo_offset);

        if (tokyoTime<10000000000){
        machineInput = machineInput1.concat(tokyoTime);
        } else {
        machineInput = machineInput2.concat(tokyoTime);
        }
        return machineInput;
    },
    kickstartBatchMint(){
       //this.nowSequance = this.form.wish;
        this.batchMint();
    },
    batchMint(){

        
        if(this.nowSequance < this.form.loop){

            this.dialog.success = false;

            // mainnet Address
            this.nowAddress = this.form.wallet;


            // convert Testnet Address to Mainnet 
            //this.nowAddress = format.address(format.hexAddress(this.winnerAddress[this.nowSequance]), 1029 );

            
            
            //convert adress 
            
            // make a random wish  25 
            let luckyWish = Math.floor(Math.random() * 42)
            console.log('wish #: ', luckyWish);
            console.log('wish : ', this.wishlist[luckyWish]);
            this.form.wish = this.wishlist[luckyWish];

            // make a random name 
            let luckyName = Math.floor(Math.random() * 106)
            console.log('wish #: ', luckyName);
            console.log('wish : ', this.namelist[luckyName]);
            this.form.name = this.namelist[luckyName];
            
            // Change wallet address
            //this.form.walletAddr = this.winnerAddress[this.nowSequance];
            this.form.walletAddr = this.form.wallet;
            let refID = this.form.guardian;
            this.payByCoins( this.generateMachineInput(), refID );  

        } else {

            this.dialog.success = true;

            this.nowAddress  = 'End :' + this.nowAddress;
            this.nowSequance = 'total Mints :'+ this.nowSequance;
        }
    },

// IPFS Start







// IPFS End



    buyNFT (form){
        
        //if (this.form.guardian>0){

            this.$refs[form].validate((valid) => {
            if (valid) {
                
                // select payment token  
                let index = this.form.payment;
                let refID = this.form.guardian;

                if(index==0){
                    this.dialog.payment = false;
                    
                    console.log('Enter BuyNFT :')
                    // Pay by native coin
                    this.payByCoins( this.generateMachineInput(), refID );


                    console.log('exit BuyNFT :')
                } else {
                    // store the token selection into payment
                    this.approvePayment();
                    //this.dialogApprove = true;
                }
            } else {
                console.log('error submit!!');
                return false;
            }
            });

        //} else {
         //   this.dialog.gamma = true;
        //}


        
    },
    async generateNewNFT(_id, _transactionHash){


        //display painting tab
        this.dialog.mint = false;
        this.dialog.painting = true;

        await contract_collectibles.Nekos(_id).call({
        from: this.accAddress
        }).then((res) => {

            /**
             * power       : _power,
             * DNA         : _DNA,
             * refCount    : _refCount,
             * gammaNekoID : _gammaNekoID
             */

            console.log('Painter :',res);

            this.newNFT.transactionHash = _transactionHash;
            this.newNFT.id      = _id;
            this.newNFT.img     = path_Image + _id + '.gif'
            this.newNFT.wish    = this.form.wish;
            this.newNFT.name    = this.form.name;
            this.newNFT.guardian= this.form.guardian;
            this.newNFT.DNA     = res.DNA.toString();
            this.newNFT.gammaID = res.gammaNekoID.toString();
            this.newNFT.power   = res.power[0].toString(); 
            this.newNFT.refCount= res.refCount.toString();

            console.log('gen new NFT',this.newNFT);
            this.generateFile ();

            
        })
    },
    async generateFile (){
            // generate Metadata and GIF
            //const {data:res2} = 
            await this.$http.post('/painter/', this.newNFT).then((res) => {
                console.log('api data : ', res);

            //display success tab
            this.dialog.painting = false;
            this.dialog.success = true;

            //increment 
            this.nowSequance++;
            this.batchMint();
                
            }).catch((err) => {
                console.log(err, 'err! generate NFT images. Retry');
                this.generateFile();
            });

    },
 
    async payByCoins (_machine, _refNekoId){    

        // display minting tab
        this.dialog.mint = true;
        const [connectedAddress] = await provider.request({method: 'cfx_accounts'})
        console.log('Pay by CFX',_machine, ', ',_refNekoId, ', ', connectedAddress);
        console.log('Price :',this.payment.tokens[0].tokenSymbol , this.payment.tokens[0].tokenQuantity);

        //auto swap storage limit
        let storageLimintVal = 0;
        if (this.newGas==true){
            storageLimintVal = '0x140' //320
            this.newGas = false;
        } else {
            storageLimintVal = '0x100' //256
        }



        console.log ('Minting by Private Key : ', sender.address);

        // Change Generation

        await contract_collectibles.mintRootNeko( this.form.walletAddr, _machine, this.form.generation) 
            .sendTransaction({
            from: sender.address,
            to: contract_collectibles.address,
            gas: '0x1fbd0', // 95,000 0x17318 , 130,000  0x1fbd0 small charater only
            storageLimit: storageLimintVal, // 192 0xc0 , 320 0x140, 256 0x100
            gasPrice: '0x3b9aca00', //1000000
            }).executed().then((txHash) => {
                console.log(txHash);
                console.log('tx hash only :', txHash['transactionHash']);
                this.getTransactionReceipt(txHash['transactionHash']);
            });



    },
    getTransactionReceipt(txHash){
        setTimeout(() => {
                provider.request({ method: 'cfx_getTransactionReceipt', params: [txHash]})
                .then((receipt) => {
                    console.log('txReceipt ',receipt)
                    // EVENT PAYMENT,  payee > address,  _nftid > uint256,  token > address, amount >uint256
                    let _txHash = txHash;
                    let _newNFTid = parseInt(receipt.logs[0].topics[3], 16);
                    //let _newNFTid = 183

                    console.log("transactionHash:",_txHash )
                    console.log('New NFT ID : ',_newNFTid);

                    this.dialogMint = false;
                    this.generateNewNFT(_newNFTid, _txHash );

                    console.log("purchase receipt", receipt)
                    
                }).catch((err) => {
                    console.log(err, 'err!! retry again');
                    let _txHash = txHash;
                    //this.$message.error("Minting Failed");
                    // display minting tab
                    //this.dialog.mint = false;
                    this.getTransactionReceipt(_txHash);
                });
            }, 10000);
    },
    async approvePayment (){
        // Approve : amount of ERC20 to transfer

        //console.log('Price :',this.payment.tokens[_paymentTokenIndex].tokenSymbol , this.payment.tokens[_paymentTokenIndex].tokenQuantity*10**18);
        // ok 21, 22, __ , 24, 25

        console.log('sign by wallet : ', this.account);

        await contract_neko.approve(contract_payment.address , '100000000000000000000000').sendTransaction({
        from: this.account,
        }).then((res) => {

            console.log('approve ',res);
            
            //this.dialogApprove = false;
            this.dialogMint = true;
            //this.allowancePayment ();
        })
    },
    async payByERC20Token (_refNekoId){
        // pay with other ERC20 tokens
        let _machine = this.generateMachineInput();
        
        console.log("enter payByToken: machinecode : ",  _machine);

        await window.conflux.enable().then((currentWalletAddr) => {

            console.log('Connect Accounts :', currentWalletAddr);

            //contract_neko
            contract_payment.paymentByToken(contract_neko.address, _machine, _refNekoId).sendTransaction({
                from: currentWalletAddr,
                gas: '0xF4240', //1,000,000
                storageLimit: '0x7D0', //2000
                gasPrice: '0x64', //100
            }).executed().then((receipt) => {
                console.log (receipt);

                this.dialogMint = false;

                let _txHash = receipt.transactionHash;
                let _newNFTid = parseInt(receipt.logs[2].topics[3], 16);
                this.generateNewNFT(_newNFTid, _txHash );

                console.log("transactionHash:",_txHash )
                console.log('New NFT ID : ',_newNFTid);

            }).catch((err) => {
                console.log(err, 'err!!');
                ///this.$message.error("Minting Failed");
                // display minting tab
                this.dialog.mint = false;
            });
        });
    },

// Retrieve General Information
    async getNeko(){

        await contract_neko.balanceOf(contract_collectibles.address).then((res) => {
            this.poolBalance = (res.toString()/1e18).toFixed(5);
            console.log('Collectible Contract $NEKO Bal :',(res.toString()/1e18).toFixed(5));
        }).catch((err) => {
            console.log(err, 'err');
        });
    },
    async getGeneralDetails(){
        await contract_collectibles.balanceOf(this.account).call().then((res) => {
            this.NFT.balance = parseInt(res);
            console.log('No. NFT owned :', parseInt(res));
        }).catch((err) => {
            console.log(err, 'err');
        });
    },
    async getNFTOwned (){        
        await contract_collectibles.ownedNekos().call({
            from: this.account,
        }).then((res) => {
            this.NFT.owned = res.toString();
            //console.log('async Neko owned :', res);
        }).catch((err) => {
            console.log(err, 'err');
        });

    },
    async getNFTSupply (){        
        await contract_collectibles.totalSupply().call({
            from: this.account,
        }).then((res) => {
            this.NFT.supply = parseInt(res);
            //console.log('async Neko owned :', res);
        }).catch((err) => {
            console.log(err, 'err');
        });

    },
    checkNetworkID(){


        conflux.cfx.getId().then((res) => {
            console.log('network id: ', res);
            //console.log('async accountNekoBalance :', res);
        }).catch((err) => {
            console.log(err, 'err');
        });
    },
    async checkNekoBalance(){


        await contract_neko.balanceOf(this.account).then((res) => {
            this.accountNekoBalance = parseInt(res.toString()/1e18);
            console.log('Balance $NEKO:', (res.toString()/1e18).toFixed(2));
        }).catch((err) => {
            console.log(err, 'err');
        });


        await conflux.getBalance(this.account).then((res) => {
            console.log('DIP :', res.toString());
            console.log('$CFX :', (res.toString()/1e18).toFixed(2));
            //.div(1e18).toFixed()

        }).catch((err) => {
            console.log(err, 'err');
        });
    },
    initPaymentMethod(){
            //CFX Coin
            this.payment.tokens.push({
                tokenAddress: '0x0000000000000000000000000000000000001002',
                tokenSymbol: 'CFX',
                tokenQuantity : 0.00001,
                tokenQtyDisplay : 0.00001,
                tokenStatus: true
            });

            // Neko Token
            this.payment.tokens.push({
                tokenAddress: contract_payment.address,
                tokenSymbol: 'NEKO',
                tokenQuantity: 10**6,
                tokenQtyDisplay : 10**6,
                tokenStatus: true,
            });


            //this.form.payment = this.payment.tokens[0];
    },
    initCFXPaymentToken(){

            //CFX Coin
            this.payment.tokens.push({
                tokenAddress: '0x0000000000000000000000000000000000001002',
                tokenSymbol: 'CFX',
                tokenQuantity : 0.00001,
                tokenQtyDisplay : 0.00001,
                tokenStatus: true
            });
                    console.log ('CFX Token :', this.payment.tokens[0].tokenAddress, ', ', this.payment.tokens[0].tokenSymbol, ', ', this.payment.tokens[0].tokenQuantity, ', ',this.payment.tokens[0].tokenStatus)
    },
    async getPaymentToken(){

        await contract_payment.totalTokens().then((res) => {
            this.payment.count = parseInt(res);
            console.log('Acceptable CRC20 token :', this.payment.count);


        //set in other tokens 
        for(let i=0; i< this.payment.count ;i++){ 
            
             contract_payment.ERC20Tokens(i).then((res) => {
                if(res[3]===true){
                    this.payment.tokens.push({
                        tokenAddress: res[0],
                        tokenSymbol: res[1],
                        tokenQuantity: 10**6,
                        tokenQtyDisplay : 10**6,
                        tokenStatus: res[3],
                    });
                    console.log ('ERC20 Token :', res[0], ', ', res[1], ', ', 10**6, ', ',res[3])
                    console.log ('all payment tokens :', this.payment.tokens)
                }
            }).catch((err) => {
                console.log(err, 'err');
            });
        }

        //set in CFX
        this.initCFXPaymentToken();


        }).catch((err) => {
            console.log(err, 'err');
        });
            // this had pick the right token , CFX
            this.form.payment = this.payment.tokens[0]

    },

  }
}
</script>

<style scoped>
h1{
    width: 25%;
    font-size: 2em;
    color:rgba(255, 255, 0, 0.7);
    margin:0 auto;
    font-weight: 100;
    margin-bottom: 30px;
    text-align: center;
}
.el-header{
    justify-content: left;
}
.el-card {
    background:none;
    margin:auto 0;
    border: none;
}
.el-icon-picture{
    width:195px;
    height: 95px;
    background-image: url('../../src/assets/images/slot-in.png');
}
.el-icon-picture:before {
    content:none;
}
.gashapon{
    width:293px;
    height:462px;
    background-image: url('../../src/assets/images/gashapon.png');
    margin: 0 auto;
}
.gashapon:hover{
    width:293px;
    height:462px;
    background-image: url('../../src/assets/images/gashapon.png');
    margin: 0 auto;
    margin-top : 3px;
}
.el-button--gashapon {
    background-color: yellow;
    color:tomato;
    border: 1px solid tomato;
}
.dialog-input-wrap{
    width:500px; 
    height:420px;
    margin:0 auto; 
    padding-bottom:15px; 
    padding-top:20px; 
    padding-left:20px; 
    padding-right:20px; 
    border:1px solid #000;  
    background:rgba(0, 0, 99, 0.8);  
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
.dialog-content{
    height:300px;
}
.dialog-footer-msg{
    color:white;
}
.btn_twister{
    cursor: pointer;
}

.btn_twister:hover{
  -webkit-transform:rotate(110deg);
  -moz-transform: rotate(110deg);
  -ms-transform: rotate(110deg);
  -o-transform: rotate(110deg);
  transform: rotate(110deg);
}

.dailog_payment {
    text-align: center;
}
.dialog-footer {
    text-align: center;
}
.dialog-footer a{
    text-decoration: none;
    color:#ff3600;;
    padding:5px;
}
.el-dialog {
    padding-top:60px;
    padding-bottom:40px;
    text-align: center;
}
.wallet-connection{
    width: 460px;
    text-align: right;
    color: #c6c6c6;
    float:right;
    margin-right:30px;
}
.token-wrap{
    width: 460px;
    height: 30px;
    padding:20px;
    margin-top:-20px;
    background: #3f3f3f;
    border-radius: 0 0 10px 10px;
    background:rgba(0, 0, 0, .4); 
    border:solid 1px #0086ff;
}
.cfx-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-cfx.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:140px;
    float:left;
    color:white;
}
.neko-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-neko.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:140px;
    color:#fcee21;
    float:left;
}
.meow-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-meow.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:60px;
    color:#fcee21;
    float:left;
}
</style>