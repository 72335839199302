<template>
    <div class="main-container">

        <!-- Wallet connectd -->
        <div v-if="display.isConnect" class="wallet-connection"  >
            <div class="token-wrap">
                <div class="cfx-balance">{{this.accountCFXBalance}} CFX</div>
                <div class="neko-balance">{{this.accountNekoBalance}} NEKO</div>
                <div class="meow-balance">{{this.accountMeowBalance}}</div>
                <div  style="float:left;font-size:0.6em; color:#666;"> Conflux Network : {{shortenAccount}} </div>
            </div>
        </div>


        <!-- Wallet not connected -->
        <div v-if="!display.isConnect" class="wallet-connection"  >
            <div class="token-wrap">
                <div class="cfx-balance"> Conflux networks</div>
                <el-button 
                    style="font-size:1.2em; margin-top:-5px; background-color:#ffe000; color:#ff3600; border:1px solid #ff3600;" 
                    @click="updateButton()" v-if="!display.isConnect"> Connect wallet
                </el-button>
            </div>
        </div>

        <h1>Marketplace</h1>
    <el-menu :default-active="activeIndex" class="el-menu" style="width:1024px; margin:0 auto; margin-top:100px;" mode="horizontal" router>
      <el-menu-item index="/receivedgift">Received</el-menu-item>
      <el-menu-item index="/sentgift">Sent</el-menu-item>
    </el-menu>


  <el-card style="width:1024px; margin:0 auto;">
    <div> Gift </div>
  
  <el-table :data="gifts.filter(data => !search || data.TokenID.includes(search))" stripe style="width: 100%">
    <el-table-column prop="tokenId" label="招き猫 #"> </el-table-column>   
    <el-table-column prop="receiver" label="Receiver" > </el-table-column>

      <el-table-column align="right">
        <template  slot="header">
            <el-input v-model="search" size="mini" placeholder="Token ID"/>
        </template>

        <template slot-scope="scope">
          <el-button size="mini"> 
            <router-link :to="'/token/' +scope.row.tokenId +'/0x0'"> View </router-link>
          </el-button>


          <el-button size="mini"> 
            <router-link :to="'/token/' +scope.row.tokenId + '/' + account"> share </router-link>
          </el-button>

      
          <el-button size="mini" type="danger"> BUY </el-button>
        </template>
      </el-table-column>         
  </el-table>

  <el-alert title="Loading ..." type="warning" show-icon v-if="isAlert"></el-alert>
  </el-card>

  </div>
</template>

<script>


import abi_collectible from '../web3/abi_collectible';
import abi_neko from '../web3/abi_neko';

const { Conflux} = require('js-conflux-sdk');
const conflux = new Conflux({
    url: "https://test.confluxrpc.com",
    networkId: 1
    });

conflux.provider = window.conflux

//connect Collectibles Contract 
const contract_collectibles =  conflux.Contract({abi:abi_collectible, address:'cfxtest:aca9ft2fc251ya1egvmj41xtbf7y5cmd6jyf3ey1tg'});

//connect Neko Contract
const contract_neko = conflux.Contract({abi:abi_neko, address:'cfxtest:achefjeg32maw46mga4ntspyd7kfnx3bze91kh16yc'});


export default {
  data() {
    return {
      accountNekoBalance:0,
      accountCFXBalance:0,
      accountMeowBalance:0,
      gifts : [],
      activeIndex: '1',
      web3: null,
      account: null,
      contract :[],
      totalDeals:0,
      search :0,
      formLabelWidth: '120px',
      data : [],
      isAlert : false,
      display :{
          isConnect : false,
      },
    };
  },

  mounted() {
    if (typeof window.conflux !== "undefined") {
        console.log('Conflux portal is installed!');
        this.updateData();
        this.getLogNow();
    } else {
        console.log('Please install Conflux portal, go to confluxnetwork.org');
    }


  },
  methods:{

  async updateData(){
      await window.conflux.request({method: "cfx_requestAccounts"});

      if (typeof window.conflux !== "undefined"){
          console.log('Conflux Portal is installed!');

      try {

          const cfx = window["conflux"]
          const accounts = await cfx.request({method: "cfx_requestAccounts"})
          this.account = accounts[0]
          this.shortenAccount = accounts[0].substring(0, 10) + '...' + accounts[0].substring(44, 50)
          this.display.isConnect = true;

          console.log('Connect Accounts :', this.account);

          this.checkWalletBalance();

      } catch (error) {
          if (error.code === 4001) {
              // EIP 1193 userRejectedRequest error
              console.log("Please connect to ConfluxPortal.")
          } else {
              console.error(error)
          }
      }
      }


  },
  async checkWalletBalance(){
          // NEKO Balance
          await contract_neko.balanceOf(this.account).then((res) => {
              this.accountNekoBalance = (parseInt(res.toString()/1e18));
              console.log('Balance $NEKO:', (parseInt(res.toString()/1e18)));
          }).catch((err) => {
              console.log(err, 'err');
          });

          // CFX Balance
          await conflux.getBalance(this.account).then((res) => {
              this.accountCFXBalance = (res.toString()/1e18).toFixed(5);
          }).catch((err) => {
              console.log(err, 'err');
          });

          // Total NFT Owned 
          await contract_collectibles.balanceOf(this.account).call({
              from: this.account,
          }).then((res) => {
              this.accountMeowBalance = parseInt(res);
          }).catch((err) => {
              console.log(err, 'err');
          });


  },

  async getLogNow (){
    /** 
    await contract_collectibles.GIFT(this.account, null, null).then(function(events){
      console.log(events) // same results as the optional callback above
    });
    */

    // event GIFT (address indexed sender, address indexed receiver,  uint256 NekoId);
    // event BIRTH (address owner, uint256 NekoId, uint256 power, uint256 DNA);

    var depositEventAll = contract_collectibles.BIRTH({
      owner:this.account
      }, {fromBlock: 68807806, toBlock: 68807956});

    depositEventAll.watch(function(err, result) {
      if (err) {
        console.log(err)
        return;
      }
      console.log('gift : ', result);
    })
/** 
    let logs = await conflux.cfx.getLogs({
      fromEpoch: 68807856,
      toEpoch: 68807856,
      address: 'cfxtest:type.contract:aca9ft2fc251ya1egvmj41xtbf7y5cmd6jyf3ey1tg',
      limit: 1
    });

    console.log(logs) // same results as the optional callback above
*/

  },

  async getLogsOfTransfer() {

  let latestEpoch  = 0;

  this.isAlert = true;
  // Get the latest epoch Number
  await conflux.getEpochNumber().then((receipt) => {
    console.log('epochNumber :', receipt)
    latestEpoch = receipt - 1;
  })

  //contract.myEvent.getLogs
  // GIFT (msg.sender, receiverAddrs,  tokenId);
  
  // any `_send`, any `_receiver`, any `_nekoID`
  const { address, topics } = contract_collectibles.GIFT(this.account, null, null); 

    for (let i=68628432; i<latestEpoch ;i+=999){

      let valTo = i + 999;

      if(valTo>latestEpoch){
        valTo = latestEpoch;
      }

      //console.log('val to :', valTo)

      const logs = await conflux.GIFT.getLogs({ address, topics, fromEpoch: i, toEpoch: valTo });
      if(logs.length != 0){

        //console.log('get logs :',logs);
        let decoded = contract_collectibles.abi.decodeLog(logs[0]);
        console.log('decoded :',decoded);

            this.gifts.push({
              sender    : decoded.array[0],
              receiver  : decoded.array[1],
              tokenId   : decoded.array[2]
            })
      }
    }

    this.isAlert = false;

  },

  async getApprovedDeal(){
    this.isAlert = true;

/** 
let logs = await conflux.cfx.getLogs({
  fromEpoch: 58850462,
  toEpoch: 58851461,
  address: 'cfxtest:type.user:aapn5b8dw3bsg471sazzyzwms9u5ngjw6yzszb3m37',
  limit: 5000
});
*/

let logs = await conflux.cfx.getLogs({
  fromEpoch: 58850462,
  toEpoch: "latest_state",
  address: 'cfxtest:type.contract:acbz06p71gzn1e00c3j06gwgnebvgdw1cj50pg1teh',
  limit: 5000
});

console.log('event return :', logs);




let decoded = contract_collectibles.abi.decodeLog(logs[2]);
console.log('decoded :',decoded);


    await contract_collectibles.GIFT.getLogs('GIFT',{
      filter: { sender: this.account },
      fromEpoch: 0,
      toEpoch: "latest_state"
      }).then((events) => {

        for (let i=0; i < events.length ; i++){
          console.log('i  :', i);
            this.deals.push({
              sender    : events[i].returnValues.sender,
              receiver  : events[i].returnValues.receiver,
              tokenId   : events[i].returnValues.NekoId
            })
          
        }

        console.log('GIFT : ', events);
        console.log('events length :', events.length);
        console.log('Gifts : ', this.deals);
        this.isAlert = false;
      })
    },
  }
  
}
</script>

<style lang="less" scoped>

.main-container{
    display: block;
}
h1{
    width: 25%;
    font-size: 2em;
    color:rgba(255, 255, 0, 0.7);
    margin:0 auto;
    font-weight: 100;
    margin-bottom: 30px;
    text-align: center;
}
.wallet-connection{
    width: 460px;
    text-align: right;
    color: #c6c6c6;
    float:right;
    margin-right:30px;
}
.token-wrap{
    width: 460px;
    height: 30px;
    padding:20px;
    margin-top:-20px;
    background: #3f3f3f;
    border-radius: 0 0 10px 10px;
    background:rgba(0, 0, 0, .4); 
    border:solid 1px #0086ff;
}
.cfx-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-cfx.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:140px;
    float:left;
    color:white;
}
.neko-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-neko.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:140px;
    color:#fcee21;
    float:left;
}
.meow-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-meow.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:60px;
    color:#fcee21;
    float:left;
}
</style>