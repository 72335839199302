 <!-- Public Access Page : Guardian ID  -->

<template>
    <div class="main-container">

        <!-- Wallet connectd -->
        <div v-if="isConnect" class="wallet-connection"  >
            <div class="token-wrap">
                <div class="cfx-balance">{{accountCFXBalance}} CFX</div>
                <div class="neko-balance">{{accountNekoBalance}} NEKO</div>
                <div class="meow-balance">{{accountNFTBalance}}</div>
                <div  style="float:left;font-size:0.6em; color:#666;"> Conflux Network : {{accAddressStorten}} </div>
            </div>
        </div>


        <!-- Wallet not connected -->
        <div v-if="!isConnect" class="wallet-connection"  >
            <div class="token-wrap">
                <div class="cfx-balance"> Conflux networks</div>
                <el-button 
                    style="font-size:1.2em; margin-top:-5px; background-color:#ffe000; color:#ff3600; border:1px solid #ff3600;" 
                    @click="connectButton()"> Connect wallet
                </el-button>
            </div>
        </div>

<div  v-if="!isNoImage">
    <div class="nft_info">
        <!-- Multi tab -->
        <el-card style="background:none;">
            <el-tabs type="border-card"  style="width: 500px;  margin-bottom:400px; background:black; border:none;">
                <!-- NFT Image -->
                <el-tab-pane label="NFT"  class="nft-image" >
                    
                    <img :src="NFT.image" class="image">
                </el-tab-pane>


                <!-- Maneki Power -->
                <el-tab-pane label="Maneki power">
                    <el-table style="width:440px;" :data="NFT.attributes" stripe>
                        <el-table-column prop="trait_type" label="MANEKI POWER" width="180"> </el-table-column>
                        <el-table-column prop="value" label="value #" > </el-table-column>      
                    </el-table>
                </el-tab-pane>

                <!-- Chart -->
                <el-tab-pane label="Five Energies"  class="el-card-chart" >
                    <radar-chart :chart-data="chartData" :options="options" style=" width:400px; height:400px;background:white; padding-left:30px; padding-right:15px;"></radar-chart>
                </el-tab-pane>

                <!-- Certificate -->
                <el-tab-pane label="Certificate">
                        <el-table  :data="certificate" stripe style="width:440px;" >
                            <el-table-column prop="trait_type" label="Birth Certification" width="170"> </el-table-column>
                            <el-table-column prop="value" > </el-table-column>      
                        </el-table>
                        <div style="background-color:white; width:440px;">
                        <el-link :href=NFT.txURL target="_blank" class="linkVerify" type="primary" style="background-color:white; text-align:center; margin:0 auto; ">Verify Certificate</el-link>
                        </div>
                    <div style="clear:both;"></div>
                </el-tab-pane>
            </el-tabs>
        </el-card>


        <!-- General details  -->
        <el-card  style="width: 500px; height:65px; background:none; margin-bottom:5px;">
            <div v-if="NFT.name" >
                <div class="el-card-name">{{NFT.name}}</div>
            </div>
        </el-card>

        <el-card  style="width: 500px; height:200px; background : #ffe600;">
            <div v-if="NFT.description" >
                <div class="el-card-wish">
                    <div style="background:#00ba2c; height:80px; padding:10px; color:#3f3f3f;  border-radius: 10px 10px 0 0;">{{NFT.description}}</div>
                </div>
            </div>

            <div >
                <div class="el-card-neko"> 
                    <div style="background:#7ac943; height:40px; padding:10px; color:#3f3f3f; border-radius:0 0 10px 10px;">{{ NFT.piggyBank }} NEKO</div>
                </div>
            </div>
        </el-card>


     
       <!-- last price and sell Button -->
        <el-card  style="width: 500px; height:120px; background:#FFF6AD; text-align:center;">
            <div style="padding-bottom:10px; height:80px;float:left; width:35%;"> Last price<br>
                <span style="font-size:1.8em;"> {{ NFT.lastPrice }}</span> 
                <br>
                CFX
            </div>
            <div style="padding-bottom:10px; height:80px;float:left; width:30%; border-left:1px solid black;"> Blessing<br>
                <span style="font-size:1.8em;">{{2 - NFT.refCount}}</span>
                <br>
                left
            </div>
            <div style="padding-bottom:10px; height:80px; float:left; width:30%; border-left:1px solid black;"> Gene pool<br>
                <span v-if="NFT.gamma > 0" style="font-size:1.8em;">{{NFT.gamma}}</span>
                <span v-else style="font-size:1.8em;">-</span>
                <br>
                Meow#
            </div>

        </el-card>

        <!-- Action Button -->
        <el-card  style="width: 500px; height:100px;">
            <router-link v-if="(2 - NFT.refCount) >0" :to="'/metapass/' + NFT.id "> 
                <el-button > Metapass</el-button>
            </router-link>
            &nbsp;

            <router-link v-if="(2 - NFT.refCount) >0" :to="'/wish/' + NFT.id "> 
                <el-button > Use as guardian </el-button>
            </router-link>
            &nbsp;
            <el-button @click="sendGift(NFT.id)">Gift</el-button>
            <el-button style="float:right; width:100px;" @click="openApproveSellDialog(NFT.id)">Sell</el-button>
        </el-card>


    </div>


        <!-- Approve to sell -->
        <el-dialog  :visible.sync="dialog.approveToSell" >  
            <div class="dialog-input-wrap">       
   
                <div class="dialog-content">
                    <div style="color:white; font-size:1.8em; padding-top:100px; padding-left:80px;padding-bottom:20px; "> 
                        <span style="color:yellow; font-size:0.7em;">販売のために公開</span> <br> Publish for sale
                    </div>
            
                    <el-form :model="sellApproveForm">
                        <el-form-item  style="width:350px; margin: 0 auto; height:200px">
                        <el-select v-model="sellApproveForm.tokenID" placeholder="Select NFT">
                            <el-option :key="'neko-'+sellApproveForm.tokenID" :label="'招き猫 #'+sellForm.tokenID" :value="sellForm.tokenID"></el-option>
                        </el-select>
                        </el-form-item>
                    </el-form>
                </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="dialog.approveToSell = false">Cancel</el-button>
                <el-button type="primary" @click="approveToSell()"> Approve </el-button>
            </div>
            </div>
        </el-dialog>
        


        <!-- Want to sell -->
        <el-dialog  :visible.sync="dialog.wantToSell" :close-on-click-modal="false" >
            <div class="dialog-input-wrap"> 

                <div class="dialog-content" style="height:350px;">
                    <div style="color:white; font-size:1.8em; padding-top:5px; padding-left:80px;padding-bottom:20px; "> 
                        <span style="color:yellow; font-size:0.7em;">オークションオファー</span> <br> Auction offer
                    </div>

                    <el-form :model="sellForm" :rules="sellRules" label-width="140px" >

                        <el-form-item label="Meow" style=" display:none;  margin: 0 auto; height:60px">
                        <el-select v-model="sellForm.tokenID" placeholder="Select NFT">
                            <el-option :key="'neko-'+sellForm.tokenID" :label="'招き猫 #'+sellForm.tokenID" :value="sellForm.tokenID"></el-option>
                        </el-select>
                        </el-form-item>


                        <el-form-item  label="Accept"  style=" margin: 0 auto; height:60px; ">
                            <el-select v-model="sellForm.paymentToken" placeholder="Pay by">
                                <el-option :key="'CFX'" :label="'CFX'" :value="'cfx:aathhv7a493sc2r6p5g5se7ehjpxsrtcy6863hna4v'"></el-option>
                                <!-- <el-option label="USDT" value="cfxtest:acepe88unk7fvs18436178up33hb4zkuf62a9dk1gv"></el-option> -->
                                <!-- <el-option label="NEKO" value="cfxtest:aca9u5eu3zvmjv6gdudvf21g9uj2cm60upya76k4e4"></el-option> -->
                            </el-select>
                        </el-form-item>


                        <el-form-item  prop="startPrice" label="Start price"  style="width:width:400px; margin: 0 auto; height:60px;">
                            <el-input-number size="medium" v-model="sellForm.startPrice"></el-input-number>
                            <!--el-input v-model="sellForm.startPrice" placeholder="Highest price" ></el-input> -->
                        </el-form-item>

                        <el-form-item label="Floor price"  style="width:width:400px; margin: 0 auto; height:60px; ">

                            <el-input-number size="medium" v-model="sellForm.endPrice" :disabled="false"></el-input-number>
                            <!-- <el-input v-model="sellForm.endPrice" placeholder="Lowest price" ></el-input> -->
                        </el-form-item>

                        <el-form-item  label="End (Day)"  style="width:width:400px; margin: 0 auto; height:60px;">
                            <el-input-number size="medium" v-model="sellForm.duration" :disabled="true"></el-input-number>
                            <!--<el-input v-model="sellForm.duration" placeholder="Duration (Hour)" value="24"></el-input>-->
                        </el-form-item>
                    </el-form>
                </div>

                    <div slot="footer" class="dialog-footer">
                        <el-button @click="dialog.wantToSell = false">Cancel</el-button>
                        <el-button type="primary" @click="wantToSell()"> Approve </el-button>
                    </div>
            </div>
        </el-dialog>


        <!-- Approving -->
        <el-dialog  :visible.sync="dialog.approving" :close-on-click-modal="false" >
            <div class="dialog-input-wrap" style="color:white;">
                <div class="dialog-content">
                    <div style="color:white; font-size:1.8em; padding-top:150px; width:350px; margin:0 auto; "> 
                        <span style="color:yellow; font-size:0.7em;">承認待ち</span> <br> Waiting for approval 
                        <br> to publish MEOW 猫 #{{NFT.id}}
                    </div>
                </div>
            </div>
        </el-dialog>


        <!-- Publishing -->
        <el-dialog  :visible.sync="dialog.publishing" :close-on-click-modal="false" >
            <div class="dialog-input-wrap">
                <div class="dialog-content">
                    <div style="color:white; font-size:1.8em; padding-top:150px; width:350px; margin:0 auto; "> 
                        <span style="color:yellow; font-size:0.7em;">出版</span> <br> Publishing MEOW 猫 #{{NFT.id}}
                    </div>
                </div>
            </div>
        </el-dialog>


        <!-- Published -->
        <el-dialog  :visible.sync="dialog.successPublish" :close-on-click-modal="false" >
            <div class="dialog-input-wrap">
                <div class="dialog-content">
                    <div style="color:white; font-size:1.8em; padding-top:120px; width:350px; margin:0 auto; "> 
                        <span style="color:yellow; font-size:0.7em;">正常に公開</span> <br> Publish successfully
                        <br> MEOW 猫 #{{NFT.id}}
                    </div>
                    <div style="width:350px; margin:0 auto; padding-bottom:30px;"> Blockchain Tx : <br>
                        <el-link :href=NFT.TxLogURL target="_blank" class="linkVerify" type="primary">{{ NFT.TxLog }}</el-link>
                    </div>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="$router.push('/wallet')"> Sell more </el-button>
                    <el-button type="primary" @click="$router.push('/auction/' + NFT.id + '/0x0/')"> View auction</el-button>
                </div>
            </div>
        </el-dialog>


        <!-- Send as gift -->
        <el-dialog  :visible.sync="dialog.sendGift" >
            <div class="dialog-input-wrap">
                <h2 style="color:white;"> Send to a friend </h2>
            

            <el-form :model="giftForm">
                <el-form-item  >
                <el-select v-model="giftForm.tokenID" placeholder="Select NFT">
                    <el-option :key="'neko-'+giftForm.tokenID" :label="'招き猫 #'+giftForm.tokenID" :value="giftForm.tokenID"></el-option>
                </el-select>
                </el-form-item>

                <el-form-item >
                <el-input v-model="giftForm.receiverAddr" placeholder="Conflux wallet cfx:aapn..."></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialog.sendGift = false">Cancel</el-button>
                <el-button type="primary" @click="approveSendGift()"> Approve </el-button>
            </div>
            </div>
        </el-dialog>

      <!-- Install Fluent -->
        <el-dialog  :visible.sync="dialogInstall" :close-on-click-modal="false" >
            <div class="dialog-input-wrap">
                <div class="dialog-content">
                    <div style="color:white; font-size:1.8em; padding-top:120px; padding-bottom:20px; width:350px; margin:0 auto; "> 
                        <span style="color:yellow; font-size:0.7em;"></span> Connect to a wallet
                    </div>
                    <div class="walletButton">
                    <a href="https://fluentwallet.com/" target="_blank" >
                        <div style="width:250px; height:35px; color:black; font-size:1.7em; font-weight:100; float:left; "> Install Fluent Wallet </div>
                        <img style="width:35px; float:left; " src="../../src/assets/images/fluent-logo.png" />
                    </a>
                    </div>
                    <div style="color:#666; font-size:1em; padding-top:80px; width:350px; margin:0 auto; "> 
                        New to Conflux? <a href="https://fluentwallet.com/" target="_blank" style="color:#666;"> Learn more about wallet </a>
                    </div>
                </div>
            </div>
        </el-dialog>

      <!-- Activate address Fluent -->
        <el-dialog  :visible.sync="dialogActivate" :close-on-click-modal="false" >
            <div class="dialog-input-wrap">
                <div class="dialog-content">
                    <div style="color:white; font-size:1.8em; padding-top:120px; padding-bottom:20px; width:350px; margin:0 auto; "> 
                        <span style="color:yellow; font-size:0.7em;"></span> Connect to a wallet
                    </div>
                    <div class="walletButton" @click="authorizedWallet()">
                        <div style="width:250px; height:35px; color:black; font-size:1.7em; font-weight:100; float:left; "> Fluent Wallet </div>
                        <img style="width:35px; float:left; " src="../../src/assets/images/fluent-logo.png" />
                    </div>
                    <div style="color:#666; font-size:1em; padding-top:80px; width:350px; margin:0 auto; "> 
                        New to Conflux? <a href="https://fluentwallet.com/" target="_blank" style="color:#666;"> Learn more about wallet </a>
                    </div>
                </div>
            </div>
        </el-dialog>
</div>

        <!-- Form - Part 4, painting  -->
        <el-dialog :visible.sync="dialog.painting">
            
            <div class="dialog-input-wrap" style="color:white;">
                <img src="../../src/assets/images/painting.gif" style="width:500px; height:350px; margin:0 auto;"/>

                <div class ="dialog-footer-msg">
                    Painting NFT : 
                    <br>
                    {{ newNFT.name }} (招き猫#{{ newNFT.id }}) : {{ newNFT.wish }} 
                    <br>
                    Maneki Power {{ newNFT.power.toLocaleString() }}
                </div>
            </div>


        </el-dialog>

    </div>
</template>

<script>

import abi_collectible from '../web3/abi_collectible';
import abi_exchange from '../web3/abi_exchange';
import abi_neko from '../web3/abi_neko';

import RadarChart from '../charts/RadarChart';

//Conflux SDK
const { Conflux } = require('js-conflux-sdk');
const conflux = new Conflux({
  url: 'https://main.confluxrpc.com',
  networkId: 1029,
});

console.log(conflux.version);
const provider = window.conflux


//connect Collectibles Contract 

const contract_collectibles =  conflux.Contract({abi:abi_collectible, address:'cfx:acedfgnc6tp0g8t2yy35gmr4pw4cjk7sgus4fmktnn'});
const contract_neko = conflux.Contract({abi:abi_neko, address:'cfx:acejgycpayveh1heb94c1yrdrd1y5t9mhpbmatdsx3'});
const contract_marketplace = conflux.Contract({abi:abi_exchange, address:'cfx:acdtn35x2bhabgknjambku8ztr9sp2gmh2ppr9u5d1'});


const path_TxLogURL = 'https://confluxscan.io/transaction/';
const path_Metadata = 'https://md.maneki.market/token/';
const path_Image = 'https://m.maneki.market/';



export default {
    name: 'Maneki-Meow',
    metaInfo() {
        return {
        title: 'Maneki-Meow #'+ this.$route.params.id, 
        }
    },
    components: {
        RadarChart
    },
    data(){
        return{
            isConnect: false,
            accountCFXBalance : 0,
            accountNekoBalance : 0,
            accountNFTBalance : 0,
            accAddress : '0x0',
            accAddressStorten : '0x0',
            dialogInstall : false,
            dialogActivate : false,
            sellRules: {
                    startPrice: [
                        {type: 'number', message: 'Must be number', trigger: 'blur'},
                        { min: 1, max: 1000000, message: 'Limit to max 1,000,000', trigger: 'blur' }
                    ],
            }, 
            contractInstance:null,
            account:null,
            contract :{
                collectibles:null,
                payment:null,
            },
            display :{
                isConnect : false,
            },
            newNFT : {
                id      : '',
                DNA     : '',
                gammaID : '',
                power   : '',
                refCount: '',
                wish    : ''
            },
            NFT: {
                id : null,
                transactionHash : null,
                txURL     : null,
                valuation : null,
                scarcity  : null,
                name:null,
                description:null,
                external_url:null,
                art_dna:null,
                image: path_Image + this.$route.params.id + '.gif',
                wish:null,
                piggyBank : null,
                lastPrice : null,
                attributes: [{
                    trait_type:null,
                    value:null,
                    display_type:null
                }],
                TxLog : null,
                TxLogURL : null
            },
            energies : {
                wealth      : 0,
                opportunity : 0,
                relationship: 0,
                wisdom      : 0,
                health      : 0
            },
            // Radarchart Setting and data
            chartData : {
                labels: [ "Wealth",	"Opportunity","Relationship", "Wisdom",	"Health"],
                datasets: [
                {
                    label: 'Maneki Power',
                    borderWidth: 0.5,
                    fill: true,
                    backgroundColor: 'rgba(255, 99, 132,0.2)',
                    borderColor: 'rgb(255, 99, 132,0.2)',
                    pointBackgroundColor: 'rgb(255, 99, 132)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(255, 99, 132)',
                    data: [0, 0, 0, 0, 0]
                },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scale: {
                    ticks: {
                    min: 0,
                    max: 99999,
                    stepSize: 30000
                    }
                }
            },
            valuation : [],
            certificate : [],
            dialog :{
                approveToExchange : false,
                offerForm : false,
                sendGift : false,
                wantToSell : false,
                approveToSell : false,
                publishing : false,
                successPublish : false,
                approving : false,
                painting : false
            },
            giftForm : {
                tokenID: '',
                receiverAddr:''
            },
            sellApproveForm : {
                tokenID: '',
            },
            sellForm : {
                sellerAddr :'',
                tokenID: '',
                paymentToken : 'cfx:aathhv7a493sc2r6p5g5se7ehjpxsrtcy6863hna4v',
                startPrice : 150,
                endPrice : 50,
                duration :30,
            },
            formLabelWidth : '120px',
            isNoImage : false,
        }
    },
    mounted() {
        this.initWallet();
 
        provider.on('accountsChanged', accounts => {
            this.accAddressStorten= accounts[0]
            this.initWallet()
            this.isConnect = true
            this.dialogActivate = false
            console.log('accountsChanged = ', accounts)
        })
 
        provider.on('chainChanged', chainId => {
            console.log('chainChanged,  = ', chainId)
            provider.request({method: 'cfx_netVersion'}).then(networkId => {
            console.log('networkId,  = ', networkId)
            })
        })


        this.getMetadata(this.$route.params.id);
        
        
    },
    methods :{

        initWallet(){
            
            // Fluent Extention Installed?
            if (window?.conflux?.isFluent) {
                // Installed
                this.walletInitialized()
            } else {
                //Fluent Not install, Request to make installation
                console.log('Fluent is not connected, please try refresh this page or restart your browser.')
                // Appear dialog , LINK - request install Fluent 
                //this.dialogInstall = true
            }
        },
        connectButton(){
            
            // Fluent Extention Installed?
            if (window?.conflux?.isFluent) {
                // Installed, Request authorized with wallet
                //this.walletInitialized()
                this.dialogActivate = true

            } else {
                //Fluent Not install, Request to make installation
                console.log('Fluent is not connected, please try refresh this page or restart your browser.')
                // Appear dialog , LINK - request install Fluent 
                this.dialogInstall = true
            }
        },
        async authorizedWallet(){
            console.log('Requesting account')
            provider
            .request({
            method: 'cfx_requestAccounts',
            })
            .then(this.walletInitialized())
            .catch(error => console.error('error', error.message || error))
        },
        async walletInitialized() {
            console.log('Fluent Wallet Connected')

            provider.request({method: 'wallet_getFluentMetadata'}).then(({version}) => {
                console.log('Fluent version :',version);
            })

            const [chainId, networkId, alreadyAuthedAddresses] = await Promise.all([
            provider.request({method: 'cfx_chainId'}),
            provider.request({method: 'cfx_netVersion'}),
            provider.request({method: 'cfx_accounts'}),
            ])

            console.log('initialized')
            console.log('chainId :',  chainId)
            console.log('networkId: ', networkId)          
            
            if (!alreadyAuthedAddresses.length) {
                // Appear Dialog - Coneect to a wallet FLUENT
                console.log ('no address',alreadyAuthedAddresses )

            } else {
                console.log ('Wallet Address :',alreadyAuthedAddresses[0])
                this.accAddress = alreadyAuthedAddresses[0]
                this.accAddressStorten  = alreadyAuthedAddresses[0]
                this.isConnect = true
                this.fetchData()
            }            


        },

        fetchData(){
                this.getNekoTokenBalance();
                this.getCFXBalance();
                this.getNFTBalance();
                this.getNFTDetails(this.$route.params.id);
        },

        async getNekoTokenBalance (){      
            await contract_neko.balanceOf(this.accAddress).then((res) => {
                console.log('Balance $NEKO:', (res.toString()/1e18).toFixed(2));
                this.accountNekoBalance = (res.toString()/1e18).toFixed(2);
            }).catch((err) => {
                console.log(err, 'err');
            });
        },

        async getCFXBalance(){
                await conflux.getBalance(this.accAddress).then((res) => {
                console.log('$CFX :', (res.toString()/1e18).toFixed(2));
                this.accountCFXBalance = (res.toString()/1e18).toFixed(2);
            }).catch((err) => {
                console.log(err, 'err!!');
            });
        },

        async getNFTBalance (){
            const balance = await contract_collectibles.balanceOf(this.accAddress);
            
            this.accountNFTBalance = balance[0];
            console.log('Total Guardian Owned:',balance); 
        },


        fillData (a,b,c,d,e) {

            console.log('pre-filling :');

            this.chartData = {
                labels: [ "Wealth",	"Opportunity","Relationship", "Wisdom",	"Health"],
                datasets: [
                {
                    label: 'Maneki Power',
                    borderWidth: 0.5,
                    fill: true,
                    backgroundColor: 'rgba(255, 99, 132,0.2)',
                    borderColor: 'rgb(255, 99, 132,0.2)',
                    pointBackgroundColor: 'rgb(255, 99, 132)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(255, 99, 132)',
                    data: [a, b, c, d, e]
                },
                ]
            }
            console.log('filling oppotunity:',b);
        },
        getCertificate (){

            this.certificate.push ({
                trait_type: 'Token ID',
                value: this.$route.params.id,
            });

            this.certificate.push ({
                trait_type: 'Name',
                value: this.NFT.name,
            });

            this.certificate.push ({
                trait_type: 'Guardian',
                value: this.NFT.guardian,
            });
            this.certificate.push ({
                trait_type: 'Gamma',
                value: this.NFT.gamma,
            });
            this.certificate.push ({
                trait_type: 'DNA',
                value: this.NFT.art_dna,
            });
            this.certificate.push ({
                trait_type: 'Blockchain Certificate',
                value: this.NFT.transactionHash,
            });


        },
        async getNFTDetails(_id){

            await contract_collectibles.Nekos(_id).call().then((res) => {

                this.NFT.id           = _id;
                this.NFT.power        = res[0]; 
                this.NFT.DNA          = res[1];
                this.NFT.refCount     = parseInt(res[2]);
                this.NFT.gamma        = parseInt(res[3]);
                this.NFT.piggyBank    = parseInt(res[4].toString()/10**18).toLocaleString() ;
                this.NFT.lastPrice    = parseInt(res[5].toString()/10**18).toLocaleString() ;


                console.log('Blessing used: ', parseInt(res[2]));
                
            })    

        },
        async getMetadata(id){
            const axios = require('axios');
            await axios.get(path_Metadata + id)
            .then((res) => {
                console.log ('metadata :',res.data);
                //import data form metadata
                this.NFT.id = id;
                this.NFT.transactionHash = res.data.transactionHash;
                this.NFT.txURL  = path_TxLogURL + res.data.transactionHash;
                this.NFT.name = res.data.name;
                this.NFT.guardian = res.data.guardian;
                this.NFT.gamma = res.data.gamma;
                this.NFT.description = res.data.description;
                this.NFT.external_url = res.data.external_url;
                this.NFT.art_dna = res.data.art_dna;
                this.NFT.image = res.data.image;
                this.NFT.attributes = res.data.attributes;

                //fill in data for charting
                this.fillData(
                    parseInt(res.data.attributes[5].value.toString().replace(',', '')),
                    parseInt(res.data.attributes[6].value.toString().replace(',', '')),
                    parseInt(res.data.attributes[7].value.toString().replace(',', '')),
                    parseInt(res.data.attributes[8].value.toString().replace(',', '')),
                    parseInt(res.data.attributes[9].value.toString().replace(',', ''))
                    );

                //birth certification 
                this.getCertificate();

                console.log('Guardian Metadata', this.NFT);

            }).catch((err) => {
                console.log(err, 'err');
                console.log('no image');
                this.isNoImage =true
                this.dialog.painting = true

                // generate NFT image
                this.generateNFT(id, 0, '', '', 123)
            });    
        },
        imageLoadError () {
            console.log('Image failed to load');
        },


        sendGift(_tokenID){
            console.log('send gift activated')
            this.giftForm.tokenID = _tokenID;
            this.dialog.sendGift = true;
        },
        openApproveSellDialog(_tokenID){
            console.log('Approve to sell activated')
            this.sellApproveForm.tokenID =_tokenID;
            this.sellForm.tokenID = _tokenID;
            this.dialog.approveToSell = true;
        },
        async approveToSell(){
            console.log('Approve for selling')
            // sell approve able to transfer to marketplace, via COLLECTIBLE contract

            this.dialog.approveToSell = false;
            this.dialog.approving = true;
 
            const [connectedAddress] = await provider.request({method: 'cfx_accounts'})

            const tx = {
            from: connectedAddress,
            to: contract_collectibles.address,
            data: contract_collectibles.approve(
                contract_marketplace.address,
                this.sellApproveForm.tokenID,
            ).data,
            }
         
            provider
            .request({method: 'cfx_sendTransaction', params: [tx]})
            .then(result => {
                    console.log('Approved ',result);
                    this.dialog.approving = false;
                    this.dialog.wantToSell = true;
                    this.$message.success('approve marketplace to create your listing');
            })
            .catch(error => console.error('error', error.message || error))
        

/** 
           // Approve : this NFT transfer to Exchange
            await contract_collectibles.approve(contract_marketplace , this.sellApproveForm.tokenID).call({
            from: connectedAddress,
            }).then((res) => {
                    console.log('Approved ',res);
                    this.dialog.approving = false;
                    this.dialog.wantToSell = true;
                    this.$message.success('approve marketplace to create your listing');
            }).catch((err) => {
                    console.log(err, 'err!!');
                    this.$message.error("Sending Failed");
               
                
            });
*/
        },


        openWantToSellDialog (_tokenID) {
            console.log('open want to sell dialog')
            this.dialog.wantToSell = true;
            this.sellForm.tokenID = _tokenID;
        },

        async wantToSell() {
            console.log('Creating Auction')

            const [connectedAddress] = await provider.request({method: 'cfx_accounts'})

            const tx = {
                    from: connectedAddress,
                    to: contract_marketplace.address,
                    gas: '0x3d090', // 225000  0x36ee8,    250000. 0x3d090
                    gasPrice: '0x3b9aca00', //1000000
                    storageLimit: '0x7d0', //1728.  0x6c0   1350. 0x546 , 2000 0x7d0
                data: contract_marketplace.createAuction(
                    this.sellForm.tokenID,
                    this.sellForm.paymentToken,
                    this.sellForm.startPrice*(10**18),
                    this.sellForm.endPrice*(10**18),
                    this.sellForm.duration*3600*24,
                ).data,
            }
            provider.request({method: 'cfx_sendTransaction', params: [tx]}).then((txHash) => {
                    console.log('Auction Created ',txHash);
                    this.NFT.TxLog = txHash.substring(0, 15) + '...' + txHash.substring(60, 66)
                    this.NFT.TxLogURL = path_TxLogURL + txHash

                    console.log('tx hash :', this.NFT.TxLog)
                    
                    this.$message.success('Listed created');

                    this.dialog.publishing = false;
                    this.dialog.successPublish = true;
            }).catch(error => console.error('error', error.message || error))
            
        },
        async approveSendGift (){   



            console.log('Creating Auction')

            const [connectedAddress] = await provider.request({method: 'cfx_accounts'})

            const tx = {
                    from: connectedAddress,
                    to: contract_collectibles.address,
                    gas: '0x15f90', // 90,000
                    gasPrice: '0x3b9aca00', //1000000 
                    storageLimit: '0x100', //1728.  0x6c0   1350. 0x546 , 2000 0x7d0
                data: contract_collectibles.sendAsGift(
                    this.giftForm.receiverAddr,
                    this.giftForm.tokenID,
                ).data,
            }


            this.$message.success('Sending ... ');

            provider.request({method: 'cfx_sendTransaction', params: [tx]}).then((txHash) => {
                    console.log('Gift send : ',txHash);
                    this.NFT.TxLog = txHash.substring(0, 15) + '...' + txHash.substring(60, 66)
                    this.NFT.TxLogURL = path_TxLogURL + txHash

                    console.log('tx hash :', this.NFT.TxLog)
                
                    this.dialog.sendGift = false;

            }).catch(error => console.error('error', error.message || error))



        },
        async fetchTxReceipt (){
            // Read TxReceipt_nftID.json
            //  - TxHash / NULL
            //  - Name
            //  - Wish
            // generateNFT(_nftID, TxHash)
        },
        async generateNFT (_id, _transactionHash, _name, _wish, _guardian){

            console.log('Generate Meow : init parameters')
            //display painting tab
            this.dialog.mint = false;
            this.dialog.painting = true;

            await contract_collectibles.Nekos(_id).call({
            from: this.accAddress
            }).then((res) => {

                /**
                 * power       : _power,
                 * DNA         : _DNA,
                 * refCount    : _refCount,
                 * gammaNekoID : _gammaNekoID
                 */

                console.log('Painter :',res);

                this.newNFT.transactionHash = _transactionHash;
                this.newNFT.id      = _id;
                this.newNFT.img     = path_Image + _id + '.gif';
                this.newNFT.wish    = _wish;
                this.newNFT.name    = _name;
                this.newNFT.guardian= _guardian;
                this.newNFT.DNA     = res.DNA.toString();
                this.newNFT.gammaID = res.gammaNekoID.toString();
                this.newNFT.power   = res.power[0].toString(); 
                this.newNFT.refCount= res.refCount.toString();

                console.log('Generate Meow : Painting #',this.newNFT);
                this.generateFiles ();

                
            })
        },
        async generateFiles (){
                // generate Metadata and GIF
                //const {data:res2} = 
                await this.$http.post('/painter/', this.newNFT).then((res) => {

                console.log('api data : ', res);
                console.log('Generate Meow : Completed #',this.newNFT);

                // refresh page
                this.getMetadata(this.newNFT.id)
                this.isNoImage = false
                this.dialog.painting = false
                    
                }).catch((err) => {
                    console.log(err, 'err');
                    console.log('Generate Meow : Failed #',this.newNFT);
                });

        },

    }
}

</script>

<style scoped>
.main-container{
    display: block;
}

.nft_info {
    padding-top: 100px;
    width:1050px;
    margin: 0 auto;
    padding-bottom: 30px;
    z-index: 1;
}
.nft-image {
    padding:0px;
    margin:0px;
    width:440px;
    height: 440px;
}
.el-tabs--border-card{
    box-shadow: none!important; 
    padding: 0px !important;
}
.el-tabs--border-card{
    background-color: white;
    border:1px solid white;
}
.el-tabs {
    padding: 0 !important; 
}
.el-card {
    width:500px;
    float:left;
    margin-right: 20px;
    margin-bottom: 30px;
    background:rgba(0, 0, 0, .4); 
    border: none;
}
.el-card-chart {
    float:left;
    border: none;
}
.el-card-wish{
    background-image: url("../../src/assets/images/icon-wish.png");
    background-repeat: no-repeat;
    background-position: top left; 
    padding-left:60px;
    color:white; 
    font-size:1.2em;
    min-height: 100px;
}
.el-card-name {
    background-image: url("../../src/assets/images/icon-meow.png");
    background-repeat: no-repeat;
    background-position: top left; 
    padding-left:60px;
    color:white; 
    font-size:1em;
    min-height: 100px;
}
.el-card-neko {
    background-image: url("../../src/assets/images/icon-neko.png");
    background-repeat: no-repeat;
    background-position: top left; 
    padding-left:60px;
    color:white; 
    min-height: 100px;
    font-size: 2em;
    
}

.dialog-content{
    height:300px;
}
.dialog-footer {
    text-align: center;
}
.linkVerify {
    padding: 10px;
    margin:0 auto;
    text-align: right;
}

.dialog-input-wrap{
    width:500px; 
    height:420px;
    margin:0 auto; 
    padding-bottom:15px; 
    padding-top:20px; 
    padding-left:20px; 
    padding-right:20px; 
    border:1px solid #000;  
    background:rgba(0, 0, 99, 0.8);  
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
.wallet-connection{
    width: 460px;
    text-align: right;
    color: #c6c6c6;
    float:right;
    margin-right:30px;
}
.token-wrap{
    width: 460px;
    height: 30px;
    padding:20px;
    margin-top:-20px;
    background: #3f3f3f;
    border-radius: 0 0 10px 10px;
    background:rgba(0, 0, 0, .4); 
    border:solid 1px #0086ff;
}
.cfx-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-cfx.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:140px;
    float:left;
    color:white;
}
.neko-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-neko.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:140px;
    color:#fcee21;
    float:left;
}
.meow-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-meow.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:60px;
    color:#fcee21;
    float:left;
}
</style>
