<!-- Public Access Page : Connect Fluent Wallet  -->
<template>
    <div class="main-container">
        <!-- Wallet connectd -->
        <div v-if="isConnect" class="wallet-connection"  >
            <div class="token-wrap">
                <div class="cfx-balance">{{accountCFXBalance}} CFX</div>
                <div class="neko-balance">{{accountNekoBalance}} NEKO</div>
                <div class="meow-balance">{{accountNFTBalance}}</div>
                <div  style="float:left;font-size:0.6em; color:#666;"> Conflux Network : {{accAddressStorten}} </div>
            </div>
        </div>


        <!-- Wallet not connected -->
        <div v-if="!isConnect" class="wallet-connection"  >
            <div class="token-wrap">
                <div class="cfx-balance"> Conflux networks</div>
                <el-button 
                    style="font-size:1.2em; margin-top:-5px; background-color:#ffe000; color:#ff3600; border:1px solid #ff3600;" 
                    @click="connectButton()"> Connect wallet
                </el-button>
            </div>
        </div>

      <!-- Install Fluent -->
        <el-dialog  :visible.sync="dialogInstall" :close-on-click-modal="false" >
            <div class="dialog-input-wrap">
                <div class="dialog-content">
                    <div style="color:white; font-size:1.8em; padding-top:120px; padding-bottom:20px; width:350px; margin:0 auto; "> 
                        <span style="color:yellow; font-size:0.7em;"></span> Connect to a wallet
                    </div>
                    <div class="walletButton">
                    <a href="https://fluentwallet.com/" target="_blank" >
                        <div style="width:250px; height:35px; color:black; font-size:1.7em; font-weight:100; float:left; "> Install Fluent Wallet </div>
                        <img style="width:35px; float:left; " src="../../src/assets/images/fluent-logo.png" />
                    </a>
                    </div>
                    <div style="color:#666; font-size:1em; padding-top:80px; width:350px; margin:0 auto; "> 
                        New to Conflux? <a href="https://fluentwallet.com/" target="_blank" style="color:#666;"> Learn more about wallet </a>
                    </div>
                </div>
            </div>
        </el-dialog>

      <!-- Activate address Fluent -->
        <el-dialog  :visible.sync="dialogActivate" :close-on-click-modal="false" >
            <div class="dialog-input-wrap">
                <div class="dialog-content">
                    <div style="color:white; font-size:1.8em; padding-top:120px; padding-bottom:20px; width:350px; margin:0 auto; "> 
                        <span style="color:yellow; font-size:0.7em;"></span> Connect to a wallet
                    </div>
                    <div class="walletButton" @click="authorizedWallet()">
                        <div style="width:250px; height:35px; color:black; font-size:1.7em; font-weight:100; float:left; "> Fluent Wallet </div>
                        <img style="width:35px; float:left; " src="../../src/assets/images/fluent-logo.png" />
                    </div>
                    <div style="color:#666; font-size:1em; padding-top:80px; width:350px; margin:0 auto; "> 
                        New to Conflux? <a href="https://fluentwallet.com/" target="_blank" style="color:#666;"> Learn more about wallet </a>
                    </div>
                </div>
            </div>
        </el-dialog>


    </div>
    
</template>

<script>

const { Conflux } = require('js-conflux-sdk');
const conflux = new Conflux({
  url: 'https://test.confluxrpc.com',
  networkId: 1,
});
console.log(conflux.version);
const provider = window.conflux


export default {
    data(){
        return {
        isConnect: false,
        accountCFXBalance : 0,
        accountNekoBalance : 0,
        accountNFTBalance : 0,
        accAddress : '0x0',
        accAddressStorten : '0x0',
        dialogInstall : false,
        dialogActivate : false
        }
    },

    mounted() {
        this.initWallet();
 
        provider.on('accountsChanged', accounts => {
            console.log('accountsChanged, accounts = ', accounts)
            this.accAddressStorten= accounts[0]
            this.isConnect = true
            this.dialogActivate = false
        })
 
        provider.on('chainChanged', chainId => {
            console.log('chainChanged,  = ', chainId)
            provider.request({method: 'cfx_netVersion'}).then(networkId => {
            console.log('networkId,  = ', networkId)
            })
        })
    },
    methods :{
        initWallet(){
            
            // Fluent Extention Installed?
            if (window?.conflux?.isFluent) {
                // Installed
                this.walletInitialized()
            } else {
                //Fluent Not install, Request to make installation
                console.log('Fluent is not connected, please try refresh this page or restart your browser.')
                // Appear dialog , LINK - request install Fluent 
                //this.dialogInstall = true
            }
        },
        connectButton(){
            
            // Fluent Extention Installed?
            if (window?.conflux?.isFluent) {
                // Installed, Request authorized with wallet
                //this.walletInitialized()
                this.dialogActivate = true

            } else {
                //Fluent Not install, Request to make installation
                console.log('Fluent is not connected, please try refresh this page or restart your browser.')
                // Appear dialog , LINK - request install Fluent 
                this.dialogInstall = true
            }
        },
        authorizedWallet(){
            provider.request({method: 'cfx_requestAccounts'}).then(({account}) => {
                console.log('account version :',account[0]);
            })
        },
        async walletInitialized() {
            console.log('Fluent Wallet Connected')

            provider.request({method: 'wallet_getFluentMetadata'}).then(({version}) => {
                console.log('Fluent version :',version);
            })

            const [chainId, networkId, alreadyAuthedAddresses] = await Promise.all([
            provider.request({method: 'cfx_chainId'}),
            provider.request({method: 'cfx_netVersion'}),
            provider.request({method: 'cfx_accounts'}),
            ])

            console.log('initialized')
            console.log('chainId :',  chainId)
            console.log('networkId: ', networkId)          
            
            if (!alreadyAuthedAddresses.length) {
                // Appear Dialog - Coneect to a wallet FLUENT
                console.log ('no address',alreadyAuthedAddresses )

            } else {
                console.log ('Wallet Address :',alreadyAuthedAddresses[0])
                this.accAddress = alreadyAuthedAddresses[0]
                this.accAddressStorten  = alreadyAuthedAddresses[0]
                this.isConnect = true
            }            


        }
    }

}


</script>

<style>
.main-container{
    display: block;
}
.wallet-connection{
    width: 460px;
    text-align: right;
    color: #c6c6c6;
    float:right;
    margin-right:30px;
}
.token-wrap{
    width: 460px;
    height: 30px;
    padding:20px;
    margin-top:-20px;
    background: #3f3f3f;
    border-radius: 0 0 10px 10px;
    background:rgba(0, 0, 0, .4); 
    border:solid 1px #0086ff;
}
.cfx-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-cfx.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:140px;
    float:left;
    color:white;
}
.neko-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-neko.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:140px;
    color:#fcee21;
    float:left;
}
.meow-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-meow.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:60px;
    color:#fcee21;
    float:left;
}

.dialog-input-wrap{
    width:500px; 
    height:420px;
    margin:0 auto; 
    padding-bottom:15px; 
    padding-top:20px; 
    padding-left:20px; 
    padding-right:20px; 
    border:1px solid #000;  
    background:rgba(0, 0, 99, 0.8);  
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
.walletButton {
    width:300px; 
    height:35px; 
    margin:0 auto; 
    background:white; 
    padding:10px 30px; 
    border-radius:5px;
    border: 1px solid black;
    cursor:pointer;
}
</style>