<template>
    <div class="mint_container">
        <!-- Wallet connectd -->
        <div v-if="isConnect" class="wallet-connection"  >
            <div class="token-wrap">
                <div class="cfx-balance">{{accountCFXBalance}} CFX</div>
                <div class="neko-balance">{{accountNekoBalance}} NEKO</div>
                <div class="meow-balance">{{accountNFTBalance}}</div>
                <div  style="float:left;font-size:0.6em; color:#666;"> Conflux Network : {{accAddressStorten}} </div>
            </div>
        </div>


        <!-- Wallet not connected -->
        <div v-if="!isConnect" class="wallet-connection"  >
            <div class="token-wrap">
                <div class="cfx-balance"> Conflux networks</div>
                <el-button 
                    style="font-size:1.2em; margin-top:-5px; background-color:#ffe000; color:#ff3600; border:1px solid #ff3600;" 
                    @click="connectButton()"> Connect wallet
                </el-button>
            </div>
        </div>



        <h1> 資産 Assets </h1> 

        <div style="background:none; margin:50px auto;">

                <div style="width:1000px; margin:0 auto; background:black; height:100px; margin-bottom:10px;"> 
                    <div style="padding:20px;">

                        <el-form :model="searchFrom"  ref="searchFrom">
                            <div style="float:left; padding : 3px 10px;">                
                                <el-form-item prop="walletAddress" style="width:500px; margin: 0 auto;">
                                    <el-input type="input" v-model="searchFrom.walletAddress" placeholder="Conflux Wallet Address (cfx:aan....) "  ></el-input>
                                </el-form-item>
                            </div>
                            <div style="float:left; padding : 3px 10px;">                
                                <el-button type="gashapon" @click="searching()">Check</el-button>
                            </div>
                        </el-form>

                    </div>
                </div>
                <div style="width:1000px; margin:0 auto; background:black; height:130px; ">
                    <div class="tab">
                        <div class="tbl_summery_top"> CFX </div> 
                        <div class="tbl_summery_bottom"> {{accountCFXBalance}} </div>
                    </div>

                    <div class="tab" style="border-left:1px solid white;">
                        <div class="tbl_summery_top"> NFT </div>
                        <div class="tbl_summery_bottom"> {{accountNFTBalance}} MEOW</div>
                    </div>
                
                    <div class="tab" style="border-left:1px solid white;">
                        <div class="tbl_summery_top"> NEKO </div>
                        <div class="tbl_summery_bottom"> {{accountNekoBalance}}</div>
                    </div>
                </div>
            </div>



            <div style="width:1000px; margin:0 auto ;background: rgba(255,255,255,0.2); background-radius:10px;padding:10px 0 ;">
                <img style="width:220px; margin:10px; float:left;border:1px solid #CCC;" src="https://bafkreif5hntxpr2y2s2mqca5uyxj4tfitcyjpsqw2bcbwbsnn2c723nuiy.ipfs.nftstorage.link/" />
                <img style="width:220px; margin:10px; float:left;border:1px solid #CCC;" src="https://bafkreiehkvrxcbf2cds2wahtskso44c7ozh2obp47ykvklw7bnljgc4igq.ipfs.nftstorage.link/" />
                <img style="width:220px; margin:10px; float:left;border:1px solid #CCC;" src="https://bafkreie5io3nyp3gww4l6r3ld4s2rmfwffni2fvnueqq66zuwhqzk4j4ja.ipfs.nftstorage.link/" />
                <img style="width:220px; margin:10px; float:left;border:1px solid #CCC;" src="https://bafkreieqqn7qdzgzt5r6pnhe55g5dap2bptasfnvvt4ekwdgzrkfubrvru.ipfs.nftstorage.link/" />
                <div style="clear:both;"></div>
            </div>

            <div style="width:1000px; margin:0 auto ;background: rgba(255,255,255,0.2); background-radius:10px; padding-bottom:50px;">
            <ul class="list">
                <li class="item"  v-for="(item) in NFTs" :key="item.id" >
                    <div class="item-wrap">
                        <router-link :to="'/token/' + item.id +'/0x0/'">
                        <div class="el-card-name">
                            <div> 
                                <div v-if="item.name"><span style="color:#0375fc;">招き猫 MEOW <span style="font-weight:bold">#{{item.id}}</span></span>  {{item.name}} </div>
                                <div v-if="!item.name">Maneki 🐱 <span style="color:#0375fc;">招き猫 MEOW <span style="font-weight:bold">#{{item.id}}</span></span></div>
                                <div>{{item.description}}</div>
                            </div>
                        </div>
                        </router-link>
                    </div>
                </li>
                <div style="clear:both;"></div>
            </ul>
            </div>






        <!-- Download wallet -->
        <el-dialog title="Download or connect wallet" :visible.sync="downloadWallet">

            <div class="dialog-input-wrap">


                <div class="dialog-content">
                    <div style="margin: 0 auto; color:white; font-size:1.6em; text-align:center; padding-top:50px; padding-bottom:5px; width:350px; ">
                        <span style="color:yellow; font-size:0.8em;">Maneki-MeowをConfluxウォレットで<br>安全にチェーンに保管する</span><br>
                        Store Maneki-Meow <br>safely on-chain with
                        <br>
                        <img  style="margin-top:30px; width:220px;" src="https://fluentwallet.com/assets/logo.b3425fdb.svg" />
                    </div>
                </div>

                <div slot="footer" class="dialog-footer">
                    <el-button style="font-size:1.2em; margin-top:30px; background-color:#ffe000; color:#ff3600; border:1px solid #ff3600; "  > 
                        <a href="https://chrome.google.com/webstore/detail/fluent/eokbbaidfgdndnljmffldfgjklpjkdoi" target="_blank">
                            Free downlaod 
                        </a>
                    </el-button>
                    <div style="margin-top:60px;"> 
                        New to Conflux? 
                        <a href="https://fluentwallet.com/" target="_blank"> Learn more about wallets</a>
                    </div> 
                </div>

            </div>
        </el-dialog>

      <!-- Install Fluent -->
        <el-dialog  :visible.sync="dialogInstall" :close-on-click-modal="false" >
            <div class="dialog-input-wrap">
                <div class="dialog-content">
                    <div style="color:white; font-size:1.8em; padding-top:120px; padding-bottom:20px; width:350px; margin:0 auto; "> 
                        <span style="color:yellow; font-size:0.7em;"></span> Connect to a wallet
                    </div>
                    <div class="walletButton">
                    <a href="https://fluentwallet.com/" target="_blank" >
                        <div style="width:250px; height:35px; color:black; font-size:1.7em; font-weight:100; float:left; "> Install Fluent Wallet </div>
                        <img style="width:35px; float:left; " src="../../src/assets/images/fluent-logo.png" />
                    </a>
                    </div>
                    <div style="color:#666; font-size:1em; padding-top:80px; width:350px; margin:0 auto; "> 
                        New to Conflux? <a href="https://fluentwallet.com/" target="_blank" style="color:#666;"> Learn more about wallet </a>
                    </div>
                </div>
            </div>
        </el-dialog>

      <!-- Activate address Fluent -->
        <el-dialog  :visible.sync="dialogActivate" :close-on-click-modal="false" >
            <div class="dialog-input-wrap">
                <div class="dialog-content">
                    <div style="color:white; font-size:1.8em; padding-top:120px; padding-bottom:20px; width:350px; margin:0 auto; "> 
                        <span style="color:yellow; font-size:0.7em;"></span> Connect to a wallet
                    </div>
                    <div class="walletButton" @click="authorizedWallet()">
                        <div style="width:250px; height:35px; color:black; font-size:1.7em; font-weight:100; float:left; "> Fluent Wallet </div>
                        <img style="width:35px; float:left; " src="../../src/assets/images/fluent-logo.png" />
                    </div>
                    <div style="color:#666; font-size:1em; padding-top:80px; width:350px; margin:0 auto; "> 
                        New to Conflux? <a href="https://fluentwallet.com/" target="_blank" style="color:#666;"> Learn more about wallet </a>
                    </div>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>


import abi_collectible from '../web3/abi_collectible';
//import abi_exchange from '../web3/abi_exchange';
import abi_neko from '../web3/abi_neko';

//Conflux SDK
const { Conflux } = require('js-conflux-sdk');
const conflux = new Conflux({
  url: 'https://main.confluxrpc.com',
  networkId: 1029,
});

console.log(conflux.version);
const provider = window.conflux


const contract_collectibles =  conflux.Contract({abi:abi_collectible, address:'cfx:acedfgnc6tp0g8t2yy35gmr4pw4cjk7sgus4fmktnn'});
const contract_neko = conflux.Contract({abi:abi_neko, address:'cfx:acejgycpayveh1heb94c1yrdrd1y5t9mhpbmatdsx3'});

const path_Metadata = 'https://md.maneki.market/token/';


export default {
    data(){
        return{
            //searchAddress : 'cfx:aakm8cnzwf31gj84t90v50f7c8fxp0rphp23en6m0n',
            searchAddress : '',
            isConnect: false,
            accountCFXBalance : 0,
            accountNekoBalance : 0,
            accountNFTBalance : 0,
            accAddress : '0x0',
            accAddressStorten : '0x0',
            dialogInstall : false,
            dialogActivate : false,
            searchFrom : {
                walletAddress :0,
            },
            activeIndex: '1',
            contractInstance:null,
            account:null,
            isCollapse:false,
            tokenSupply:0,
            name:null,
            loading : true,
            
            display :{
                isConnect : false,
            },
            // NFT 
            totalGuardian :0,
            NFT : {
                owned:0,
                supply:0,
                balance:0
            },
            NFTs :[],
            search: '',
            contract :{
                collectibles:null,
                exchange :null
            },
            formLabelWidth: '120px',
            coinPrice :'',
            web3:null,
            downloadWallet: false,
        }
    },
    mounted() {
        if(this.$route.params.address!=0){
            this.searchAddress = this.$route.params.address;
            this.searchFrom.walletAddress = this.$route.params.address;
        } else {
            this.searchFrom.walletAddress = null;
        }

        this.initWallet();
 
        provider.on('accountsChanged', accounts => {
            this.accAddressStorten= accounts[0]
            this.initWallet()
            this.isConnect = true
            this.dialogActivate = false
            console.log('accountsChanged = ', accounts)
        })
 
        provider.on('chainChanged', chainId => {
            console.log('chainChanged,  = ', chainId)
            provider.request({method: 'cfx_netVersion'}).then(networkId => {
            console.log('networkId,  = ', networkId)
            })
        })
    },
    methods :{

        searching (){
            window.location.href = 'https://art.maneki.market/#/search/' + this.searchFrom.walletAddress;
            this.searchAddress = this.searchFrom.walletAddress
            this.fetchData()
        },

        initWallet(){
            
            // Fluent Extention Installed?
            if (window?.conflux?.isFluent) {
                // Installed
                this.walletInitialized()
            } else {
                //Fluent Not install, Request to make installation
                console.log('Fluent is not connected, please try refresh this page or restart your browser.')
                // Appear dialog , LINK - request install Fluent 
                //this.dialogInstall = true
            }
        },
        connectButton(){
            
            // Fluent Extention Installed?
            if (window?.conflux?.isFluent) {
                // Installed, Request authorized with wallet
                //this.walletInitialized()
                this.dialogActivate = true

            } else {
                //Fluent Not install, Request to make installation
                console.log('Fluent is not connected, please try refresh this page or restart your browser.')
                // Appear dialog , LINK - request install Fluent 
                this.dialogInstall = true
            }
        },
        async authorizedWallet(){
            console.log('Requesting account')
            provider
            .request({
            method: 'cfx_requestAccounts',
            })
            .then(this.walletInitialized())
            .catch(error => console.error('error', error.message || error))
        },
        async walletInitialized() {
            console.log('Fluent Wallet Connected')

            provider.request({method: 'wallet_getFluentMetadata'}).then(({version}) => {
                console.log('Fluent version :',version);
            })

            const [chainId, networkId, alreadyAuthedAddresses] = await Promise.all([
            provider.request({method: 'cfx_chainId'}),
            provider.request({method: 'cfx_netVersion'}),
            provider.request({method: 'cfx_accounts'}),
            ])

            console.log('initialized')
            console.log('chainId :',  chainId)
            console.log('networkId: ', networkId)          
            
            if (!alreadyAuthedAddresses.length) {
                // Appear Dialog - Coneect to a wallet FLUENT
                console.log ('no address',alreadyAuthedAddresses )

            } else {
                console.log ('Wallet Address :',alreadyAuthedAddresses[0])
                this.accAddress = alreadyAuthedAddresses[0]
                this.accAddressStorten  = alreadyAuthedAddresses[0]

                this.isConnect = true
                this.fetchData()
            }            


        },

        fetchData(){
                this.getNekoTokenBalance();
                this.getCFXBalance();
                this.getNFTBalance();
                
                this.getNFTOwned ();
                
        },

        async getNekoTokenBalance (){      
            await contract_neko.balanceOf(this.searchAddress).then((res) => {
                console.log('Balance $NEKO:', (res.toString()/1e18).toFixed(2));
                this.accountNekoBalance = (res.toString()/1e18).toFixed(2);
            }).catch((err) => {
                console.log(err, 'err');
            });
        },

        async getCFXBalance(){
                await conflux.getBalance(this.searchAddress).then((res) => {
                console.log('$CFX :', (res.toString()/1e18).toFixed(2));
                this.accountCFXBalance = (res.toString()/1e18).toFixed(2);
            }).catch((err) => {
                console.log(err, 'err!!');
            });
        },

        async getNFTBalance (){
            const balance = await contract_collectibles.balanceOf(this.searchAddress);
            
            this.accountNFTBalance = balance[0];
            console.log('Total Guardian Owned:',balance); 
        },




       async updateData(){
            await window.conflux.request({method: "cfx_requestAccounts"});

            if (typeof window.conflux !== "undefined"){
                console.log('Conflux Fluent is installed 2!');

            try {

                const cfx = window["conflux"]
                const accounts = await cfx.request({method: "cfx_requestAccounts"})

                this.account = accounts[0]
                this.shortenAccount = accounts[0].substring(0, 6) + '...' + accounts[0].substring(44, 49)
                this.display.isConnect = true;

                console.log('Connect Accounts :', this.account);


                //this.checkWalletBalance();

                // Store Coins Balance 
                this.getUSDTTokenBalance();
                this.getNekoTokenBalance();
                this.getCFXBalance();
                this.getTotalGuardian();

                this.getNFTOwned ();



            } catch (error) {
                if (error.code === 4001) {
                    // EIP 1193 userRejectedRequest error
                    console.log("Please connect to Fluent Wallet.")
                } else {
                    console.error(error)
                }
            }
            }


        },
        async checkWalletBalance(){
                // NEKO Balance
                await contract_neko.balanceOf(this.account).then((res) => {
                    this.accountNekoBalance = parseInt(res.toString()/1e18);
                    console.log('Balance $NEKO:', (parseInt(res.toString()/1e18)));
                }).catch((err) => {
                    console.log(err, 'err');
                });

                // CFX Balance
                await conflux.getBalance(this.account).then((res) => {
                    this.accountCFXBalance = (res.toString()/1e18).toFixed(5);
                }).catch((err) => {
                    console.log(err, 'err');
                });

  

        },


      
        async getNFTOwned (){
      
            // retrive owned NFTs
            await contract_collectibles.ownedNekos().call({
                from: this.searchAddress,
            }).then((res) => {
        
                console.log ('NFT :',res);

            for (let i = 0; i < res.length; i += 1) {
                // retrive NFT Details
                const axios = require('axios');
                axios.get(path_Metadata + res[i])
                .then((metadata) => {

                    var generation = parseInt(metadata.data.attributes[0].value);
                    var gen_Display = 0;

                    if (generation<6){
                        if(generation==1) { gen_Display = "α" }
                        if(generation==2) { gen_Display = "β" }
                        if(generation==3) { gen_Display = "γ" }
                        if(generation==4) { gen_Display = "δ" }
                        if(generation==5) { gen_Display = "ε" }
                    } else {
                        gen_Display = generation;
                    }
                    this.NFTs.push({
                        id: res[i][0],
                        power: metadata.data.attributes[3].value,
                        DNA: metadata.data.art_dna,
                        url: metadata.data.image,
                        gen : gen_Display,
                        
                        name : metadata.data.name,
                        description : metadata.data.description,
                        image : metadata.data.image,
                        gamma : metadata.data.gamma,
                    });

                }).catch((err) => {
                    console.log(err, 'err');
                    //no file fetched back 
                    this.NFTs.push({
                        id: res[i][0],
                        power: 0,
                        DNA: 0,
                        url: 'https://t2nft.maneki.market/blank_meow.gif',
                        gen : 0,
                        
                        name : '',
                        description : '',
                        image : 'https://t2nft.maneki.market/blank_meow.gif',
                        gamma : 0,
                    });
                });
            }



            this.loading = false;

            }).catch((err) => {
                console.log(err, 'err');
            });

        },

    }
}
</script>

<style scoped>
h1{
    width: 25%;
    font-size: 2em;
    color:rgba(255, 255, 0, 0.7);
    margin:0 auto;
    font-weight: 100;
    margin-bottom: 30px;
    text-align: center;
}
.Asset-Summery {
    float: left;
    min-width: 45%;
    background:rgba(128, 255, 209, .2); 
    margin:15px 20px;
    padding: 5px 10px;
}
.tab{
    width: 26%;
    float: left;
    padding:30px;
}
.tbl_summery_top {
    width:100%;
    font-size: 1.5em;
}
.tbl_summery_bottom {
    width:100%;
    font-size: 2em;
}
.list {
    top:50px;
    width: 80%;
    padding:0;
    margin:0 auto;
}
.list a:link {
    text-decoration: none;
}
.item {
    float: left;
    border: 1px solid black;
    width: 47%;
    height : 50px;
    margin:15px 7px;
    padding: 5px 5px;
    padding-bottom: 30px;
    background:rgba(0, 0, 0, .6); 
    border-radius: 10px;
    list-style: none;
}
.el-card-wish{
    background-image: url("../../src/assets/images/icon-wish.png");
    background-repeat: no-repeat;
    background-position: top left; 
    padding-top: 5px;
    margin-top:5px;
    padding-left:60px;
    color:white; 
    font-size:1.2em;
    min-height: 100px;
}
.el-card-name{
    background-image: url("../../src/assets/images/icon-meow.png");
    background-repeat: no-repeat;
    background-position: top left; 
    padding-left:60px;
    color:white; 
    font-size:1.2em;
    min-height: 70px;
}
.wallet-connection{
    width: 460px;
    text-align: right;
    color: #c6c6c6;
    float:right;
    margin-right:30px;
}

.dialog-footer {
    text-align: center;
    color: white;
}

.dialog-footer a{
    text-decoration: none;
    color:#ff3600;;
    padding:5px;
}
.dialog-input-wrap{
    width:500px; 
    height:420px;
    margin:0 auto; 
    padding-bottom:15px; 
    padding-top:20px; 
    padding-left:20px; 
    padding-right:20px; 
    border:1px solid #000;  
    background:rgba(0, 0, 99, 0.8);  
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.dialog-content{
    height:250px;
}

.token-wrap{
    width: 460px;
    height: 25px;
    padding:20px;
    margin-top:-20px;
    background: #3f3f3f;
    border-radius: 0 0 10px 10px;
    background:rgba(0, 0, 0, .4); 
    border:solid 1px #0086ff;
}
.cfx-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-cfx.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:140px;
    float:left;
    color:white;
}
.neko-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-neko.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:140px;
    color:#fcee21;
    float:left;
}
.meow-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-meow.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:60px;
    color:#fcee21;
    float:left;
}
</style>