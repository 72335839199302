import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/login.vue'
import Home from '../components/home.vue'
import Welcome from '../components/welcome.vue'
import Wallet from '../components/wallet.vue'
import Marketplace from '../components/marketplace.vue'
import MarketGenThree from '../components/marketGenThree.vue'
import MarketGroup from '../components/marketGroup.vue'
import Launchpad from '../components/launchpad.vue'
import Earn from '../components/earn.vue'
import Analytics from '../components/analytics.vue'
import Contact from '../components/contact.vue'
import Token from '../components/token.vue'
import Sale from '../components/sale.vue'
import Wish from '../components/wish.vue'
import Metapass from '../components/metapass.vue'
import WishLoop from '../components/wish_loop.vue'
import WishBatch from '../components/wish_batch.vue'
import WishRoot from '../components/wish_root.vue'
import WishPaint from '../components/wish_paint.vue'
import WishIPFS from '../components/wish_ipfs.vue'
import Clubhouse from '../components/clubhouse.vue'
import OpenDeal from '../components/opendeal.vue'
import ApprovedDeal from '../components/approveddeal.vue'
import TradeHistory from '../components/tradehistory.vue'
import ReceivedGift from '../components/receivedgift.vue'
import SentGift from '../components/sentgift.vue'
import SelectGuardian from '../components/selectGuardian.vue'
import WishingWall from '../components/wishingWall.vue'
import NFT_Featured from '../components/NFT_featured.vue'
import ViewGuardian from '../components/viewGuardian.vue'
import Auction from '../components/auction.vue'
import Painter from '../components/painter.vue'
import Generate from '../components/wish_generate.vue'
import Party from '../components/party.vue'
import Withdraw from '../components/withdraw.vue'
import Connect from '../components/connect.vue'
import Search from '../components/search.vue'


// meta web
import VueMeta from 'vue-meta'


Vue.use(VueMeta)
Vue.use(VueRouter)

const routes = [
  //{path: '/', redirect:'/marketGroup/550000/650000'},
  //{path: '/', redirect:'/MarketGenThree'},

  {path: '/', redirect:'/wishingWall'},
  {path: '/login', component:Login },
  {path: '/home', component:Home,
    redirect :'/wish/guardian', 
    children : [
      {path: '/welcome', component:Welcome },
      {path: '/wishingWall', component:WishingWall},
      {path: '/featured', component:NFT_Featured},
      {path: '/viewGuardian/:id/', component:ViewGuardian},
      {path: '/selectGuardian', component:SelectGuardian},
      {path: '/wallet', component:Wallet },
      {path: '/receivedgift', component:ReceivedGift },
      {path: '/sentgift', component:SentGift },
      {path: '/marketplace', component:Marketplace },
      {path: '/MarketGenThree', component:MarketGenThree },
      {path: '/marketGroup/:low/:high/:page', component:MarketGroup },
      {path: '/opendeal', component:OpenDeal },
      {path: '/approveddeal', component:ApprovedDeal },
      {path: '/tradehistory', component:TradeHistory },
      {path: '/launchpad', component:Launchpad },
      {path: '/earn', component:Earn },
      {path: '/clubhouse', component:Clubhouse },
      {path: '/wish/:guardian', component:Wish },
      {path: '/metapass/:guardian', component:Metapass },
      {path: '/wishLoop/:guardian', component:WishLoop },
      {path: '/wishBatch/:guardian', component:WishBatch },
      {path: '/wishRoot/:guardian', component:WishRoot },
      {path: '/wishPaint/:guardian', component:WishPaint },
      {path: '/wishIpfs/:guardian', component:WishIPFS },
      {path: '/painter/:guardian', component:Painter },
      {path: '/analytics', component:Analytics },
      {path: '/contact', component:Contact },
      {path: '/token/:id/:ref', component:Token },
      {path: '/auction/:id/:ref', component:Auction },
      {path: '/sale/:id/', component:Sale },
      {path: '/generate/:guardian', component:Generate },
      {path: '/party', component:Party },
      {path: '/withdraw/:id/:ref', component:Withdraw },
      {path: '/connect', component:Connect },
      {path: '/search/:address', component:Search }
    ]
  }
  
]
const router = new VueRouter({
  routes
})


export default router