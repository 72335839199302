<template>
    <div class="mint_container">

        <div class="tab_wrap">
            <div class="tab_1">
                <div style="width:310px; float:left; font-size:1.5em; font-weight: 100; margin-right:30px;">Special Edition : Hatty Meow </div>
            </div>
            <div class="tab_2">
                <marquee > 
                🎁 🎁 🎁  Join AMA on 21 JUNE with Maneki.Market Founder!
                🎁 Hatty Meow Giveaway 🎁 <a href="https://maneki.market/en/event-ama-eric-chan-1/" target="_blank">  Read More...</a>
                Report bugs 🎁<a href="https://discord.com/invite/Ev8aX9HQWm" target="_blank">  DISCORD </a> or <a href="https://t.me/ManekiMeow_MY" target="_blank"> Telegram </a>
                </marquee>
                <div style="width:150px; float:left; text-align:center; color:white;"> 50 / 100 MEOWS </div> 
                <div style="width:350px; float:left;"> <el-progress :percentage=parseInt(50) :stroke-width="3"></el-progress> </div>
            </div>
            <div class="tab_0">
                <a href="https://maneki.market/en/event-ama-eric-chan-1/" target="_blank">
                <img src="https://maneki.market/en/wp-content/uploads/2022/06/Update-annoncement-3-Recovered_DApp.png" class="image">
                </a>
            </div>

            
        </div>
        
        <div style="background:none; margin:50px auto;">
            
            <ul class="list" 
                v-loading="loading"
                element-loading-text="Loading on Conflux Network..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)">

                <li class="item"  v-for="(item) in NFTs" :key="item.id" >
                    <div v-if="item.name">
                        <div style="width:50; height:50px; float:left;padding:5px;"><img src="../../src/assets/images/icon-meow.png" /></div>
                        <div style="float:left; padding:10px;"> {{item.name}} <br>
                           <span style="font-size:0.8em;">MEOW 猫 <span style="font-weight:bold">#{{item.id}}</span></span> 
                        </div>



                        <div style="clear:both;"></div>
                    </div>
                    <router-link :to="'/viewGuardian/' + item.id">
                        <el-card :body-style="{ padding: '0', }">
                            <img :src="item.image" class="image">
                        </el-card>
                    </router-link>
                    <div v-if="item.description">
                        <div style="float:left; padding:10px;">
                            PRICE : CFX 20,000 
                            <br><span style="color:yellow">≈ USD 1,0000 </span>
                        </div>
                    </div>

                </li>

                <div style="clear:both;"></div>
            </ul>

        </div>
    </div>
</template>

<script>

import abi_collectible from '../web3/abi_collectible';


//Conflux SDK
const { Conflux } = require('js-conflux-sdk');
const conflux = new Conflux({
  url: 'https://main.confluxrpc.com',
  networkId: 1029,
});
    
//connect Collectibles Contract 
const contract_collectibles =  conflux.Contract({abi:abi_collectible, address:'cfx:acedfgnc6tp0g8t2yy35gmr4pw4cjk7sgus4fmktnn'});

const path_Metadata = 'https://md.maneki.market/token/';
    
    
export default {
    data(){
        return{
            activeIndex: '1',
            contractInstance:null,
            account:null,
            isCollapse:false,
            tokenSupply:null,
            name:null,
            loading : true,
            valautionBNB : 0,
            valautionBUSD : 0,

            // NFT 
            NFT : {
                owned:0,
                supply:0,
                balance:0
            },
            NFTs :[],
            search: '',
            contract :{
                collectibles:null,
                exchange :null
            },
            dialog :{
                approveToExchange : false,
                offerForm : false,
                sendGift : false,
            },
            approvalForm: {
                tokenID: ''
            },
            offerForm: {
                tokenID: '',
                price: '',
                currency:'',
                buyer:''
            },
            giftForm : {
                tokenID: '',
                receiverAddr:''
            },
            formLabelWidth: '120px',
            coinBalance : [],
            coinPrice :'',
            web3:null,
            featured : [

                '6170',
                '6169',
                '6168',
                '6167',
                '6166',
                '6165',
                '6164',

                '6130',
                '6129',
                '6128',
                '6127',
                '6126',
                '6125',
                '6124',
                '6123',
                '6122',
                '6121',

                '6131',
                '6132',
                '6133',
                '6134',
                '6135',
                '6136',
                '6137',
                '6136',
                '6139',
                '6140',
            ],

        }
    },
    mounted() {
        
            console.log('Public Retrive!');
            console.log('collectibles : ', contract_collectibles);
            this.pumpNekos();
    },
    methods :{


        pumpNekos(){
            console.log('featured nft: ', this.featured)
            for (let i = 0; i < this.featured.length; i++) {

                console.log('nft :', path_Metadata + this.featured[i])

                // retrive NFT Details
                const axios = require('axios');

                axios.get(path_Metadata + this.featured[i])
                .then((metadata) => {

                    this.NFTs.push({
                        id: this.featured[i],
                        power: metadata.data.attributes[3].value,
                        DNA: metadata.data.art_dna,

                        name : metadata.data.name,
                        description : metadata.data.description,
                        image : metadata.data.image,
                        gamma : metadata.data.gamma,
                    });

                }).catch((err) => {
                    console.log(err, 'err');
                });

            }

            this.loading = false;


        },

      
    }
}
</script>

<style scoped>
.tab_wrap {
    height: 420px;
    width: 1100px;
    margin: 0 auto;
}
.tab_0 {
    width: 1100px;
    height: 400px;
    color: yellow;
    float: left;
    padding:10px 10px;
}
.tab_1 {
    width: 530px;
    height: 50px;
    color: yellow;
    float: left;
    padding:10px 10px;
}
.tab_2 {
    width: 500px;
    height: 40px;
    border:1px solid #CCC;
    font-size: 1.1em;
    color: yellow;
    float: left;
    padding:10px 10px;
    background: black;
    border-radius: 5px;
}
h1{
    width: 25%;
    font-size: 2em;
    color:rgba(255, 255, 0, 0.7);
    margin:0 auto;
    font-weight: 100;
    margin-bottom: 30px;
    text-align: center;
}
.maneki-logo {
    width : 300px;
}
.list {
    top:50px;
    width: 1100px;
    padding:0;
    margin:0 auto;
}

.item {
    border: 1px solid black;
    width: 250px;
    height : 380px;
    float:left;
    margin:15px 5px;
    padding: 5px 5px;
    padding-bottom: 30px;
    background:rgba(0, 0, 0, .6); 
    border-radius: 10px;
    list-style: none;
}
.sticky-note {
    display: none;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

    .tab_0, .tab_1 , .tab_2 {
        display:none;
    }
    h1{
        width: 95%;
        font-size: 1em;
        color:rgba(255, 255, 0, 0.7);
        margin:0 auto;
        font-weight: 100;
        margin-bottom: 30px;
        text-align: center;
    }
    .mint_container{
        padding: 0;
        margin:0;
    }
    .maneki-logo {
        width:100%;
        margin:0 auto;
        
    }
    .el-main {
        padding:0;
        margin:0;
        border: 1px solid red;

    }
    .list {
        width: 98%;
        padding:0;
        margin:0;

    }
    .item {
        margin:0;
        margin-bottom:50px;
        width: 100%;
        height: 100%;

    }
    h1 {
        font-size:2em;
    }
    .sticky-note {
        display:block;
        width:100%;
        background:#262626;
        margin: 0;
        margin-bottom: 30px;
        border-radius:10px;
    }
    .sticky-note-text{
        padding: 20px;
    }
    .sticky-note img {
        height: 20px;
        padding-left:3px;
    }
}

</style>